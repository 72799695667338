import { OrderStatus } from "@tout-ma/general_models";
import { MYCOLORPALETTE } from "./theme";

export const getOrderColor = (currStatus?: OrderStatus) => {
    let color = MYCOLORPALETTE.SECOND;
    if (currStatus === OrderStatus.NEW) {
        color = MYCOLORPALETTE.DARKGRAY;
    }
    if (currStatus === OrderStatus.CONFIRMED) {
        color = MYCOLORPALETTE.FIRST;
    }
    if (currStatus === OrderStatus.ONDELIVERY) {
        color = MYCOLORPALETTE.FOURTH;
    }
    if (currStatus === OrderStatus.READYFORPICKUP) {
        color = MYCOLORPALETTE.SUCCESSGREEN;
    }
    if (currStatus === OrderStatus.DELIVERED) {
        color = MYCOLORPALETTE.SUCCESSGREEN;
    }
    if (currStatus === OrderStatus.CANCELED) {
        color = MYCOLORPALETTE.SECOND;
    }
    return color;
};