import { Order, OrderPaymentType, OrderStatus, OrderType } from '@tout-ma/general_models';
import { Form, Button, Card, Col, Input, Modal, Row, Table, TableColumnsType, Tag, DatePicker } from 'antd';
import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import { SearchOutlined, RedoOutlined, EditFilled } from '@ant-design/icons';
import { MYCOLORPALETTE } from '../../constants/theme';
import { format } from 'date-fns';
import OrderModal from '../../components/modals/OrderModal';
import { PrinterOutlined, CloseOutlined } from '@ant-design/icons';
import { getHistoryOrdersApi } from '../../api/orders';

type userForm = {
    date: Date;
    driverId?: number;
};

const OrdersHistoryScreen = () => {
    const [form] = Form.useForm<userForm>();
    const [HistoryDbOrders, setHistoryDbOrders] = useState<Order[]>([]);
    const [ShowDbOrderId, setShowDbOrderId] = useState<number | void>();
    const renderOrderStatus = (id?: number, statusFix?: OrderStatus) => {
        let color = MYCOLORPALETTE.SECOND;
        let status;
        if (id) {
            status = HistoryDbOrders.find((order) => order.id === id)?.status;
        } else {
            status = statusFix;
        }
        if (status === OrderStatus.NEW) {
            color = MYCOLORPALETTE.DARKGRAY;
        }
        if (status === OrderStatus.CONFIRMED) {
            color = MYCOLORPALETTE.FIRST;
        }
        if (status === OrderStatus.ONDELIVERY) {
            color = MYCOLORPALETTE.FOURTH;
        }
        if (status === OrderStatus.READYFORPICKUP) {
            color = MYCOLORPALETTE.SUCCESSGREEN;
        }
        if (status === OrderStatus.DELIVERED) {
            color = MYCOLORPALETTE.SUCCESSGREEN;
        }
        if (status === OrderStatus.CANCELED) {
            color = MYCOLORPALETTE.SECOND;
        }
        return <Tag color={color}>{status}</Tag>;
    };
    const tableColums: TableColumnsType<Order> = [
        {
            title: t('Order Time'),
            dataIndex: 'createdOn',
            key: 'key',
            align: 'center',
            render: (date) => format(new Date(date), 'HH:mm'),
        },
        {
            title: t('Type'),
            dataIndex: 'type',
            align: 'center',
            render: (type: OrderType) =>
                type === OrderType.DELIVERY ? (
                    <Tag color={MYCOLORPALETTE.FIRST} style={{ fontWeight: 'bold' }}>
                        {t('Delivery')}
                    </Tag>
                ) : (
                    <Tag color={MYCOLORPALETTE.THIRD} style={{ fontWeight: 'bold' }}>
                        {t('Pickup')}
                    </Tag>
                ),
        },
        {
            title: t('Total price'),
            dataIndex: 'totalPrice',
            align: 'center',
            render: (totalPrice) => (
                <p style={{ margin: 0, padding: 0 }}>
                    <b>{totalPrice}</b> MAD
                </p>
            ),
        },
        {
            title: t('Payment'),
            dataIndex: 'paymentType',
            align: 'center',
            render: (payment: OrderPaymentType) =>
                payment === OrderPaymentType.ONLINE ? (
                    <Tag color={MYCOLORPALETTE.FOURTH} style={{ fontWeight: 'bold' }}>
                        {t('Online')}
                    </Tag>
                ) : (
                    <Tag color={MYCOLORPALETTE.SECOND} style={{ fontWeight: 'bold' }}>
                        {t('Cash')}
                    </Tag>
                ),
        },
        {
            title: 'Status',
            align: 'center',
            render: (order) => renderOrderStatus(order.id),
        },
    ];
    const onFinish = async (values: userForm) => {
         console.log('Success:', values);
        const orderss = await getHistoryOrdersApi(values.date,values.driverId);
        setHistoryDbOrders(orderss);
        // setUsers(users.list);
        // setTotal(users.total);
    };
    const renderModalFooter = () => {
      return (
          <div style={{ width: '100%', height: '100px', border: '1px solid black', display: 'flex', flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center' }}>
              <Button icon={<PrinterOutlined style={{ color: MYCOLORPALETTE.LIGHTWHITE }} />} style={{ backgroundColor: MYCOLORPALETTE.FIRST, height: '60%' }}>
                  <b style={{ color: MYCOLORPALETTE.WHITE }}>{t('Print')}</b>
              </Button>
 
              <Button
                  onClick={() => setShowDbOrderId()}
                  icon={<CloseOutlined style={{ color: MYCOLORPALETTE.SECOND }} />}
                  style={{ backgroundColor: MYCOLORPALETTE.LIGHTWHITE, borderColor: MYCOLORPALETTE.SECOND, height: '60%' }}
              >
                  <b style={{ color: MYCOLORPALETTE.SECOND }}>{t('Close')}</b>
              </Button>
          </div>
      );
  };
    return (
        <Card title={t('Order History')} style={{ margin: 20, height: '100%' }}>
            <Card style={{ marginTop: '10px', marginBottom: '10px' }}>
                <Form layout="vertical" form={form} name="orderhistory" onFinish={onFinish}>
                    <Row style={{ alignItems: 'center', marginTop: '10px' }}>
                        <Col span={3} offset={1}>
                            <Form.Item name={'date'} label={t('Date')} rules={[{ required: true, message: t('A date is required') }]}>
                                <DatePicker />
                            </Form.Item>
                        </Col>
                        <Col span={6} offset={2}>
                            <Form.Item name={'driverId'} label={t('Driver')} rules={[{ required: false }]}>
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={10} offset={2}>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                                <Button htmlType="button" style={{ width: '45%' }} block icon={<RedoOutlined />} onClick={() => form.resetFields()}>
                                    Reset form
                                </Button>
                                <Button type="default" style={{ width: '45%' }} block icon={<SearchOutlined />} htmlType="submit">
                                    Look Up
                                </Button>
                            </div>
                        </Col>
                    </Row>
 
                </Form>
            </Card>
            <Table
                style={{ border: `1px solid ${MYCOLORPALETTE.FIRST}` }}
                dataSource={HistoryDbOrders}
                columns={tableColums}
                rowKey="key"
                onRow={(orderItem) => ({
                    onClick: () => setShowDbOrderId(orderItem.id),
                })}
            />
            <Modal width={'70vw'} style={{ top: 50 }} open={!!ShowDbOrderId} onCancel={() => setShowDbOrderId()} footer={renderModalFooter()}>
                {ShowDbOrderId ? <OrderModal closeModal={() => setShowDbOrderId()} HistoryDbOrders={HistoryDbOrders} dbOrderId={ShowDbOrderId} /> : null}
            </Modal>
        </Card>
    );
};

export default OrdersHistoryScreen;
