import { Button, Card, Col, Collapse, message, Form, GetProp, Input, InputNumber, Row, Select, UploadFile, UploadProps, Upload } from 'antd';
import React, { useContext, useState } from 'react';
import {
    ArrowLeftOutlined,
    PlusSquareOutlined,
    GoogleOutlined,
    DeleteOutlined,
    MinusCircleOutlined,
    PlusOutlined,
    RedoOutlined,
    EditOutlined,
    UploadOutlined,
    CopyFilled,
    SaveOutlined,
} from '@ant-design/icons';
import { t } from 'i18next';
import { RestauntContext } from '../../contexts/RestaurantContext';
import { useNavigate } from 'react-router-dom';
import { DefaultLocales } from '@tout-ma/general_models';
import { translateText } from '../../api/translate';
import FormItemLabel from 'antd/es/form/FormItemLabel';
import { addDish } from '../../api/dishes';
import { RcFile } from 'antd/es/upload';
import { NotificationsContext } from '../../contexts/NotificationsContext';

type formValues = {
    originalLang: DefaultLocales;
    file: any;
    name: {
        en?: string;
        fr?: string;
        es?: string;
        ar?: string;
        nl?: string;
        name: string;
    };
    description: {
        en?: string;
        fr?: string;
        es?: string;
        ar?: string;
        nl?: string;
        description: string;
    };
    price: number;
    menuCategoryId: number;
    baseOptionsInput: string[];
    baseOptionsInputTranslations: {
        [key: string]: {
            en?: string;
            fr?: string;
            es?: string;
            ar?: string;
            nl?: string;
        };
    };
    paidOptionsInput: { price: number; name: string }[];
    paidOptionsInputTranslations: {
        [key: string]: {
            en?: string;
            fr?: string;
            es?: string;
            ar?: string;
            nl?: string;
        };
    };
    requiredOptionsInput: string[];
    requiredOptionsInputTranslations: {
        [key: string]: {
            en?: string;
            fr?: string;
            es?: string;
            ar?: string;
            nl?: string;
        };
    };
    requiredOptionsInputOptionInput: {
        [key: string]: string[];
    };
    requiredOptionsInputOptionInputTranslations: {
        [key: string]: {
            [key: string]: {
                [key: string]: string;
            };
        };
    };
};
const NewMenuItemScreen = () => {
    const { Restaurant, reloadRestaurant } = useContext(RestauntContext);
    const { showNotification } = useContext(NotificationsContext);
    const navigate = useNavigate();
    const [form] = Form.useForm<any>();
    const baseOptionsInput = Form.useWatch('baseOptionsInput', form);
    const paidOptionsInput = Form.useWatch<{ price: number; name: string }[]>('paidOptionsInput', form);
    const requiredOptionsInput = Form.useWatch('requiredOptionsInput', form);
    const requiredOptionsInputOptionInput = Form.useWatch('requiredOptionsInputOptionInput', form);

    const [file, setFile] = React.useState<UploadFile | null>(null);

    const [selectedLang, setselectedLang] = useState<DefaultLocales>();
    const languages: DefaultLocales[] = [DefaultLocales.EN, DefaultLocales.FR, DefaultLocales.ES, DefaultLocales.AR, DefaultLocales.NL];

    const onTranslate = async (
        type:
            | 'baseOptionsInputTranslations'
            | 'paidOptionsInputTranslations'
            | 'requiredOptionsInput'
            | 'requiredOptionsInputOptionInput'
            | 'requiredOptionsInputOptionInputCopyPaste'
            | 'nameDescription',
        key?: string
    ) => {
        let currForm = form.getFieldsValue();
        if (!selectedLang) return;

        let languagesToTranslate = languages.filter((x) => x !== selectedLang);

        switch (type) {
            case 'nameDescription':
                for (let langLooper = 0; langLooper < languagesToTranslate.length; langLooper++) {
                    const currLang = languagesToTranslate[langLooper];
                    const name = currForm.name.name;
                    const description = currForm.description?.description;

                    if (name) {
                        let translatedName = await translateText(name, selectedLang, currLang);
                        form.setFieldsValue({ name: { [currLang]: translatedName } });
                    }
                    if (description) {
                        let translatedQuickInfo = await translateText(description, selectedLang, currLang);
                        form.setFieldsValue({ description: { [currLang]: translatedQuickInfo } });
                    }
                }
                break;
            case 'baseOptionsInputTranslations':
                for (let langLooper = 0; langLooper < languagesToTranslate.length; langLooper++) {
                    const currLang = languagesToTranslate[langLooper];
                    for (let index = 0; index < baseOptionsInput.length; index++) {
                        const optionName = baseOptionsInput[index];

                        let translatedOption = await translateText(optionName, selectedLang, currLang);
                        form.setFieldsValue({ baseOptionsInputTranslations: { [optionName]: { [currLang]: translatedOption } } });
                    }
                }
                break;
            case 'requiredOptionsInput':
                for (let langLooper = 0; langLooper < languagesToTranslate.length; langLooper++) {
                    const currLang = languagesToTranslate[langLooper];
                    for (let index = 0; index < requiredOptionsInput.length; index++) {
                        const optionName = requiredOptionsInput[index];

                        let translatedOption = await translateText(optionName, selectedLang, currLang);
                        form.setFieldsValue({ requiredOptionsInputTranslations: { [optionName]: { [currLang]: translatedOption } } });
                    }
                }
                break;
            case 'requiredOptionsInputOptionInput':
                if (key) {
                    for (let langLooper = 0; langLooper < languagesToTranslate.length; langLooper++) {
                        const currLang = languagesToTranslate[langLooper];
                        for (let index = 0; index < requiredOptionsInputOptionInput[`${key}`].length; index++) {
                            const optionName = requiredOptionsInputOptionInput[`${key}`][index];

                            let translatedOption = await translateText(optionName, selectedLang, currLang);
                            form.setFieldsValue({ requiredOptionsInputOptionInputTranslations: { [key]: { [optionName]: { [currLang]: translatedOption } } } });
                        }
                    }
                }
                break;
            case 'requiredOptionsInputOptionInputCopyPaste':
                if (key) {
                    for (let langLooper = 0; langLooper < languagesToTranslate.length; langLooper++) {
                        const currLang = languagesToTranslate[langLooper];
                        for (let index = 0; index < requiredOptionsInputOptionInput[`${key}`].length; index++) {
                            const optionName = requiredOptionsInputOptionInput[`${key}`][index];

                            form.setFieldsValue({ requiredOptionsInputOptionInputTranslations: { [key]: { [optionName]: { [currLang]: optionName } } } });
                        }
                    }
                }
                break;
            case 'paidOptionsInputTranslations':
                for (let langLooper = 0; langLooper < languagesToTranslate.length; langLooper++) {
                    const currLang = languagesToTranslate[langLooper];
                    for (let index = 0; index < paidOptionsInput.length; index++) {
                        const optionName = paidOptionsInput[index]?.name;

                        let translatedOption = await translateText(optionName, selectedLang, currLang);
                        form.setFieldsValue({ paidOptionsInputTranslations: { [optionName]: { [currLang]: translatedOption } } });
                    }
                }
                break;
            default:
                break;
        }
    };

    const onFinish = async (values: formValues) => {
        const formData = new FormData();

        if (values.baseOptionsInput) {
            formData.append('baseOptionsInput', JSON.stringify(values.baseOptionsInput));
        }
        if (values.baseOptionsInputTranslations) {
            formData.append('baseOptionsInputTranslations', JSON.stringify(values.baseOptionsInputTranslations));
        }
        if (values.description) {
            formData.append('description', JSON.stringify(values.description));
        }
        if (file) {
            formData.append('file', values.file?.file as any);
        }
        if (values.menuCategoryId) {
            formData.append('menuCategoryId', values.menuCategoryId.toString());
        }
        if (values.name) {
            formData.append('name', JSON.stringify(values.name));
        }
        formData.append('originalLang', values.originalLang);
        if (values.paidOptionsInput) {
            formData.append('paidOptionsInput', JSON.stringify(values.paidOptionsInput));
        }
        if (values.paidOptionsInputTranslations) {
            formData.append('paidOptionsInputTranslations', JSON.stringify(values.paidOptionsInputTranslations));
        }
        formData.append('price', values.price.toString());

        if (values.requiredOptionsInput) {
            formData.append('requiredOptionsInput', JSON.stringify(values.requiredOptionsInput));
        }
        if (values.requiredOptionsInputOptionInput) {
            formData.append('requiredOptionsInputOptionInput', JSON.stringify(values.requiredOptionsInputOptionInput));
        }
        if (values.requiredOptionsInputOptionInputTranslations) {
            formData.append('requiredOptionsInputOptionInputTranslations', JSON.stringify(values.requiredOptionsInputOptionInputTranslations));
        }
        if (values.requiredOptionsInputTranslations) {
            formData.append('requiredOptionsInputTranslations', JSON.stringify(values.requiredOptionsInputTranslations));
        }

        try {
            await addDish(formData);
            showNotification?.('success', 'Dish added successfully');
            reloadRestaurant?.();
            navigate(-1);
        } catch (error) {
            showNotification?.('error', 'Dish not added');
            console.log(error);
        }
    };

    return (
        <Card
            title={
                <span>
                    <Button icon={<ArrowLeftOutlined />} style={{ marginRight: 10 }} title={t('Go Back')} onClick={() => navigate(-1)} /> {t('New menu item')}
                </span>
            }
            style={{ margin: 20 }}
        >
            <Form layout="vertical" form={form} name="newCategorie" onFinish={onFinish}>
                {/* Input language and picture */}
                <Card style={{ marginTop: '10px', marginBottom: '10px' }}>
                    <Row style={{ alignItems: 'center' }}>
                        <Col span={10} offset={1}>
                            <Form.Item name="originalLang" labelAlign="left" label="Input language" rules={[{ required: true }]}>
                                <Select onChange={setselectedLang}>
                                    <Select.Option value={DefaultLocales.EN}>{t('English')}</Select.Option>
                                    <Select.Option value={DefaultLocales.FR}>{t('French')}</Select.Option>
                                    <Select.Option value={DefaultLocales.ES}>{t('Spanish')}</Select.Option>
                                    <Select.Option value={DefaultLocales.AR}>{t('Arabic')}</Select.Option>
                                    <Select.Option value={DefaultLocales.NL}>{t('Dutch')}</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={11} offset={1}>
                            <Form.Item name="file" labelAlign="left" label="Picture">
                                <Upload
                                    onRemove={() => setFile(null)}
                                    beforeUpload={(file) => {
                                        setFile(file);
                                        return false;
                                    }}
                                    fileList={file ? [file] : []}
                                >
                                    <Button icon={<UploadOutlined />}>Select File</Button>
                                </Upload>
                            </Form.Item>
                        </Col>
                    </Row>
                </Card>
                {/* name and description */}
                <Card style={{ marginTop: '10px', marginBottom: '10px' }}>
                    <Row>
                        <Col span={10} offset={1}>
                            <Form.Item name={['name', 'name']} label="name" rules={[{ required: true }]}>
                                <Input.TextArea />
                            </Form.Item>
                        </Col>
                        <Col span={10} offset={1}>
                            <Form.Item name={['description', 'description']} label="extra info" rules={[{ required: false }]}>
                                <Input.TextArea />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={22} offset={1}>
                            <Collapse
                                size="small"
                                style={{ width: '100%', marginBottom: '15px' }}
                                items={[
                                    {
                                        key: '1',
                                        label: 'Translated name & description',
                                        children: (
                                            <React.Fragment>
                                                {languages
                                                    .filter((x) => x !== selectedLang)
                                                    .map((lang) => (
                                                        <Row key={lang}>
                                                            <Col span={11}>
                                                                <Form.Item name={['name', lang]} label={`Name in '${lang}'`} rules={[{ required: true }]}>
                                                                    <Input.TextArea />
                                                                </Form.Item>
                                                            </Col>
                                                            <Col span={11} offset={1}>
                                                                <Form.Item name={['description', lang]} label={`Description in '${lang}'`} rules={[{ required: false }]}>
                                                                    <Input.TextArea />
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                    ))}
                                                <Row>
                                                    <Col span={7} offset={4}>
                                                        <Button
                                                            type="primary"
                                                            disabled={!selectedLang}
                                                            block
                                                            htmlType="button"
                                                            icon={<GoogleOutlined />}
                                                            onClick={() => onTranslate('nameDescription')}
                                                        >
                                                            Translate text
                                                        </Button>
                                                    </Col>
                                                    <Col span={7} offset={1}>
                                                        <Button htmlType="button" block icon={<RedoOutlined />} onClick={() => form.resetFields()}>
                                                            Reset form
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </React.Fragment>
                                        ),
                                    },
                                ]}
                            />
                        </Col>
                    </Row>
                </Card>
                {/* price and menu category */}
                <Card style={{ marginTop: '10px', marginBottom: '10px' }}>
                    <Row>
                        <Col span={10} offset={1}>
                            <Form.Item name="price" label="Price" rules={[{ required: true, min: 1, type: 'number' }]}>
                                <InputNumber addonAfter="MAD" />
                            </Form.Item>
                        </Col>
                        <Col span={10} offset={1}>
                            <Form.Item name="menuCategoryId" labelAlign="left" label="Menu Category" rules={[{ required: true }]}>
                                <Select>
                                    {Restaurant?.menuCategories?.map((x) => (
                                        <Select.Option key={x.id} value={x.id}>
                                            {x.name[x.name.originalLang as DefaultLocales]}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                </Card>
                {/* Required options */}
                <Card style={{ marginTop: '10px', marginBottom: '10px' }}>
                    <Row>
                        <Col span={10} offset={1}>
                            <Form.List
                                name="requiredOptionsInput"
                                rules={[
                                    {
                                        validator: async (_, names) => {
                                            // if (!names || names.length < 2) {
                                            //     return Promise.reject(new Error('At least 2 passengers'));
                                            // }
                                        },
                                    },
                                ]}
                            >
                                {(fields, { add, remove }, { errors }) => (
                                    <>
                                        {fields.map((field, index) => (
                                            <Form.Item
                                                {...(index === 0
                                                    ? {
                                                          labelCol: {
                                                              xs: { span: 24 },
                                                          },
                                                          wrapperCol: {
                                                              xs: { span: 24 },
                                                          },
                                                      }
                                                    : {
                                                          wrapperCol: {
                                                              xs: { span: 24, offset: 0 },
                                                          },
                                                      })}
                                                label={index === 0 ? 'Required options' : ''}
                                                required={false}
                                                key={`${index}-requiredOptionsInput`}
                                            >
                                                <Form.Item
                                                    {...field}
                                                    validateTrigger={['onChange', 'onBlur']}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            whitespace: true,
                                                            message: 'Please enter name or delete this field.',
                                                        },
                                                    ]}
                                                    noStyle
                                                >
                                                    <Input placeholder="Free option" style={{ width: '90%', marginRight: '15px' }} />
                                                </Form.Item>
                                                <MinusCircleOutlined className="dynamic-delete-button" onClick={() => remove(field.name)} />
                                            </Form.Item>
                                        ))}
                                        <Form.Item>
                                            <Button type="dashed" onClick={() => add()} style={{ width: '100%' }} icon={<PlusOutlined />}>
                                                Add Required Option
                                            </Button>
                                            <Form.ErrorList errors={errors} />
                                        </Form.Item>
                                    </>
                                )}
                            </Form.List>
                            <Collapse
                                size="small"
                                style={{ width: '100%', marginBottom: '15px' }}
                                items={[
                                    {
                                        key: '1',
                                        label: 'Translated Required options names',
                                        children: (
                                            <React.Fragment>
                                                {requiredOptionsInput?.length &&
                                                    requiredOptionsInput.map((optionName: string) => (
                                                        <Card size="small" key={`${optionName}-abc`} title={optionName} style={{ marginBottom: '10px' }}>
                                                            <Row>
                                                                {languages
                                                                    .filter((x) => x !== selectedLang)
                                                                    .map((lang, index) => (
                                                                        <Col span={11} offset={index % 2 ? 2 : 0} key={`${index}-Translated`}>
                                                                            <Form.Item
                                                                                name={['requiredOptionsInputTranslations', optionName, lang]}
                                                                                label={`'${lang}'`}
                                                                                rules={[{ required: false }]}
                                                                            >
                                                                                <Input />
                                                                            </Form.Item>
                                                                        </Col>
                                                                    ))}
                                                            </Row>
                                                        </Card>
                                                    ))}

                                                <Row>
                                                    <Col span={7} offset={4}>
                                                        <Button
                                                            type="primary"
                                                            disabled={!selectedLang}
                                                            block
                                                            htmlType="button"
                                                            icon={<GoogleOutlined />}
                                                            onClick={() => onTranslate('requiredOptionsInput')}
                                                        >
                                                            Translate text
                                                        </Button>
                                                    </Col>
                                                    <Col span={7} offset={1}>
                                                        <Button
                                                            htmlType="button"
                                                            block
                                                            icon={<RedoOutlined />}
                                                            onClick={() => form.resetFields(['requiredOptionsInputTranslations'])}
                                                        >
                                                            Reset form
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </React.Fragment>
                                        ),
                                    },
                                ]}
                            />
                        </Col>
                        <Col span={10} offset={1}>
                            {requiredOptionsInput?.length &&
                                requiredOptionsInput
                                    ?.filter((reqOptName: string) => reqOptName?.length)
                                    .map((reqOptName: string) => (
                                        <Collapse
                                            key={reqOptName}
                                            size="small"
                                            style={{ width: '100%', marginBottom: '15px' }}
                                            items={[
                                                {
                                                    key: reqOptName,
                                                    label: reqOptName,
                                                    children: (
                                                        <Row>
                                                            <Col span={10} offset={1}>
                                                                <Form.List
                                                                    name={['requiredOptionsInputOptionInput', reqOptName]}
                                                                    rules={[
                                                                        {
                                                                            validator: async (_, names) => {
                                                                                // if (!names || names.length < 2) {
                                                                                //     return Promise.reject(new Error('At least 2 passengers'));
                                                                                // }
                                                                            },
                                                                        },
                                                                    ]}
                                                                >
                                                                    {(fields, { add, remove }, { errors }) => (
                                                                        <>
                                                                            {fields.map((field, index) => (
                                                                                <Form.Item
                                                                                    {...(index === 0
                                                                                        ? {
                                                                                              labelCol: {
                                                                                                  xs: { span: 24 },
                                                                                              },
                                                                                              wrapperCol: {
                                                                                                  xs: { span: 24 },
                                                                                              },
                                                                                          }
                                                                                        : {
                                                                                              wrapperCol: {
                                                                                                  xs: { span: 24, offset: 0 },
                                                                                              },
                                                                                          })}
                                                                                    label={index === 0 ? `${reqOptName} option` : ''}
                                                                                    required={false}
                                                                                    key={field.key}
                                                                                >
                                                                                    <Form.Item
                                                                                        {...field}
                                                                                        validateTrigger={['onChange', 'onBlur']}
                                                                                        rules={[
                                                                                            {
                                                                                                required: true,
                                                                                                whitespace: true,
                                                                                                message: 'Please enter name or delete this field.',
                                                                                            },
                                                                                        ]}
                                                                                        noStyle
                                                                                    >
                                                                                        <Input placeholder="add option" style={{ width: '85%', marginRight: '15px' }} />
                                                                                    </Form.Item>
                                                                                    <MinusCircleOutlined className="dynamic-delete-button" onClick={() => remove(field.name)} />
                                                                                </Form.Item>
                                                                            ))}
                                                                            <Form.Item>
                                                                                <Button type="dashed" onClick={() => add()} style={{ width: '100%' }} icon={<PlusOutlined />}>
                                                                                    Add Free Option
                                                                                </Button>
                                                                                <Form.ErrorList errors={errors} />
                                                                            </Form.Item>
                                                                        </>
                                                                    )}
                                                                </Form.List>
                                                            </Col>
                                                            <Col span={12} offset={1}>
                                                                <Collapse
                                                                    size="small"
                                                                    style={{ width: '100%', marginBottom: '15px' }}
                                                                    items={[
                                                                        {
                                                                            key: '1',
                                                                            label: `Translated ${reqOptName} options`,
                                                                            children: (
                                                                                <React.Fragment>
                                                                                    {requiredOptionsInputOptionInput &&
                                                                                        requiredOptionsInputOptionInput?.[`${reqOptName}`] &&
                                                                                        requiredOptionsInputOptionInput?.[`${reqOptName}`]?.length &&
                                                                                        requiredOptionsInputOptionInput?.[`${reqOptName}`].map((optionName: string) => (
                                                                                            <Card size="small" key={optionName} title={optionName} style={{ marginBottom: '10px' }}>
                                                                                                <Row>
                                                                                                    {languages
                                                                                                        .filter((x) => x !== selectedLang)
                                                                                                        .map((lang, index) => (
                                                                                                            <Col span={11} offset={index % 2 ? 2 : 0} key={lang}>
                                                                                                                <Form.Item
                                                                                                                    name={[
                                                                                                                        'requiredOptionsInputOptionInputTranslations',
                                                                                                                        reqOptName,
                                                                                                                        optionName,
                                                                                                                        lang,
                                                                                                                    ]}
                                                                                                                    label={`'${lang}'`}
                                                                                                                    rules={[{ required: false }]}
                                                                                                                >
                                                                                                                    <Input />
                                                                                                                </Form.Item>
                                                                                                            </Col>
                                                                                                        ))}
                                                                                                </Row>
                                                                                            </Card>
                                                                                        ))}

                                                                                    <Row>
                                                                                        <Col span={6} offset={2}>
                                                                                            <Button
                                                                                                type="primary"
                                                                                                disabled={!selectedLang}
                                                                                                block
                                                                                                htmlType="button"
                                                                                                icon={<GoogleOutlined />}
                                                                                                onClick={() => onTranslate('requiredOptionsInputOptionInput', reqOptName)}
                                                                                            ></Button>
                                                                                        </Col>
                                                                                        <Col span={6} offset={2}>
                                                                                            <Button
                                                                                                disabled={!selectedLang}
                                                                                                block
                                                                                                htmlType="button"
                                                                                                icon={<CopyFilled />}
                                                                                                onClick={() => onTranslate('requiredOptionsInputOptionInputCopyPaste', reqOptName)}
                                                                                            ></Button>
                                                                                        </Col>
                                                                                        <Col span={6} offset={1}>
                                                                                            <Button
                                                                                                htmlType="button"
                                                                                                block
                                                                                                icon={<RedoOutlined />}
                                                                                                onClick={() => form.resetFields(['requiredOptionsInputOptionInputTranslations'])}
                                                                                            ></Button>
                                                                                        </Col>
                                                                                    </Row>
                                                                                </React.Fragment>
                                                                            ),
                                                                        },
                                                                    ]}
                                                                />
                                                            </Col>
                                                        </Row>
                                                    ),
                                                },
                                            ]}
                                        />
                                    ))}
                        </Col>
                    </Row>
                </Card>
                {/* Free and paid options */}
                <Card style={{ marginTop: '10px', marginBottom: '10px' }}>
                    <Row>
                        <Col span={10} offset={1}>
                            <Form.List
                                name="baseOptionsInput"
                                rules={[
                                    {
                                        validator: async (_, names) => {
                                            // if (!names || names.length < 2) {
                                            //     return Promise.reject(new Error('At least 2 passengers'));
                                            // }
                                        },
                                    },
                                ]}
                            >
                                {(fields, { add, remove }, { errors }) => (
                                    <>
                                        {fields.map((field, index) => (
                                            <Form.Item
                                                {...(index === 0
                                                    ? {
                                                          labelCol: {
                                                              xs: { span: 24 },
                                                          },
                                                          wrapperCol: {
                                                              xs: { span: 24 },
                                                          },
                                                      }
                                                    : {
                                                          wrapperCol: {
                                                              xs: { span: 24, offset: 0 },
                                                          },
                                                      })}
                                                label={index === 0 ? 'Free options' : ''}
                                                required={false}
                                                key={index}
                                            >
                                                <Form.Item
                                                    {...field}
                                                    validateTrigger={['onChange', 'onBlur']}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            whitespace: true,
                                                            message: 'Please enter name or delete this field.',
                                                        },
                                                    ]}
                                                    noStyle
                                                >
                                                    <Input placeholder="Free option" style={{ width: '90%', marginRight: '15px' }} />
                                                </Form.Item>
                                                <MinusCircleOutlined className="dynamic-delete-button" onClick={() => remove(field.name)} />
                                            </Form.Item>
                                        ))}
                                        <Form.Item>
                                            <Button type="dashed" onClick={() => add()} style={{ width: '100%' }} icon={<PlusOutlined />}>
                                                Add Free Option
                                            </Button>
                                            <Form.ErrorList errors={errors} />
                                        </Form.Item>
                                    </>
                                )}
                            </Form.List>
                            <Collapse
                                size="small"
                                style={{ width: '100%', marginBottom: '15px' }}
                                items={[
                                    {
                                        key: '1',
                                        label: 'Translated free options',
                                        children: (
                                            <React.Fragment>
                                                {baseOptionsInput?.length &&
                                                    baseOptionsInput.map((optionName: string) => (
                                                        <Card size="small" key={optionName} title={optionName} style={{ marginBottom: '10px' }}>
                                                            <Row>
                                                                {languages
                                                                    .filter((x) => x !== selectedLang)
                                                                    .map((lang, index) => (
                                                                        <Col span={11} offset={index % 2 ? 2 : 0} key={lang}>
                                                                            <Form.Item
                                                                                name={['baseOptionsInputTranslations', optionName, lang]}
                                                                                label={`'${lang}'`}
                                                                                rules={[{ required: false }]}
                                                                            >
                                                                                <Input />
                                                                            </Form.Item>
                                                                        </Col>
                                                                    ))}
                                                            </Row>
                                                        </Card>
                                                    ))}

                                                <Row>
                                                    <Col span={7} offset={4}>
                                                        <Button
                                                            type="primary"
                                                            disabled={!selectedLang}
                                                            block
                                                            htmlType="button"
                                                            icon={<GoogleOutlined />}
                                                            onClick={() => onTranslate('baseOptionsInputTranslations')}
                                                        >
                                                            Translate text
                                                        </Button>
                                                    </Col>
                                                    <Col span={7} offset={1}>
                                                        <Button htmlType="button" block icon={<RedoOutlined />} onClick={() => form.resetFields(['baseOptionsInputTranslations'])}>
                                                            Reset form
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </React.Fragment>
                                        ),
                                    },
                                ]}
                            />
                        </Col>
                        <Col span={10} offset={1}>
                            <Form.List
                                name="paidOptionsInput"
                                rules={[
                                    {
                                        validator: async (_, names) => {
                                            // if (!names || names.length < 2) {
                                            //     return Promise.reject(new Error('At least 2 passengers'));
                                            // }
                                        },
                                    },
                                ]}
                            >
                                {(fields, { add, remove }, { errors }) => (
                                    <>
                                        {fields.map((field, index) => (
                                            <Form.Item
                                                {...(index === 0
                                                    ? {
                                                          labelCol: {
                                                              xs: { span: 24 },
                                                          },
                                                          wrapperCol: {
                                                              xs: { span: 24 },
                                                          },
                                                      }
                                                    : {
                                                          wrapperCol: {
                                                              xs: { span: 24, offset: 0 },
                                                          },
                                                      })}
                                                label={index === 0 ? 'Paid options' : ''}
                                                required={false}
                                                key={index}
                                            >
                                                <Form.Item name={[field.name, 'price']} label="Price" rules={[{ required: true, min: 1, type: 'number' }]}>
                                                    <InputNumber addonAfter="MAD" />
                                                </Form.Item>
                                                <Form.Item
                                                    name={[field.name, 'name']}
                                                    // {...field}
                                                    validateTrigger={['onChange', 'onBlur']}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            whitespace: true,
                                                            message: 'Please enter name or delete this field.',
                                                        },
                                                    ]}
                                                    noStyle
                                                >
                                                    <Input placeholder="Paid option" style={{ width: '90%', marginRight: '15px' }} />
                                                </Form.Item>
                                                <MinusCircleOutlined className="dynamic-delete-button" onClick={() => remove(field.name)} />
                                            </Form.Item>
                                        ))}
                                        <Form.Item>
                                            <Button type="dashed" onClick={() => add()} style={{ width: '100%' }} icon={<PlusOutlined />}>
                                                Add Paid Option
                                            </Button>
                                            <Form.ErrorList errors={errors} />
                                        </Form.Item>
                                    </>
                                )}
                            </Form.List>
                            <Collapse
                                size="small"
                                style={{ width: '100%', marginBottom: '15px' }}
                                items={[
                                    {
                                        key: '1',
                                        label: 'Translated Paid options',
                                        children: (
                                            <React.Fragment>
                                                {paidOptionsInput?.length && paidOptionsInput?.some((x) => !x?.price || !x?.name) ? (
                                                    <React.Fragment>
                                                        <p style={{ color: 'red' }}>Please fill all the fields</p>
                                                        <p style={{ color: 'red' }}> No Translation possible yet!</p>
                                                    </React.Fragment>
                                                ) : (
                                                    paidOptionsInput
                                                        ?.filter((x) => x?.price && x?.name)
                                                        .map((optionObj) => (
                                                            <Card size="small" key={optionObj.name} title={optionObj.name} style={{ marginBottom: '10px' }}>
                                                                <Row>
                                                                    {languages
                                                                        .filter((x) => x !== selectedLang)
                                                                        .map((lang, index) => (
                                                                            <Col span={11} offset={index % 2 ? 2 : 0} key={lang}>
                                                                                <Form.Item
                                                                                    name={['paidOptionsInputTranslations', optionObj.name, lang]}
                                                                                    label={`'${lang}'`}
                                                                                    rules={[{ required: false }]}
                                                                                >
                                                                                    <Input />
                                                                                </Form.Item>
                                                                            </Col>
                                                                        ))}
                                                                </Row>
                                                            </Card>
                                                        ))
                                                )}

                                                <Row>
                                                    <Col span={7} offset={4}>
                                                        <Button
                                                            type="primary"
                                                            disabled={!selectedLang}
                                                            block
                                                            htmlType="button"
                                                            icon={<GoogleOutlined />}
                                                            onClick={() => onTranslate('paidOptionsInputTranslations')}
                                                        >
                                                            Translate text
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </React.Fragment>
                                        ),
                                    },
                                ]}
                            />
                        </Col>
                    </Row>
                </Card>
                <Row>
                    <Col span={5} offset={6}>
                        <Button type="default" block icon={<PlusSquareOutlined />} htmlType="submit">
                            Create Dish
                        </Button>
                    </Col>
                    <Col span={5} offset={1}>
                        <Button htmlType="button" block icon={<RedoOutlined />} onClick={() => form.resetFields()}>
                            Reset form
                        </Button>
                    </Col>
                    {/* <Col span={5} offset={1}>
                        <Button
                            htmlType="button"
                            block
                            icon={editingCategoryId ? <DeleteOutlined /> : <CloseOutlined />}
                            danger
                            onClick={() => {
                                if (editingCategoryId) {
                                    onMenuCategoryDelete();
                                } else {
                                    form.resetFields();
                                    setshowAddingNewCategorie(false);
                                }
                            }}
                        >
                            {editingCategoryId ? 'Delete' : 'Cancel'}
                        </Button>
                    </Col> */}
                </Row>
            </Form>
        </Card>
    );
};

export default NewMenuItemScreen;
