import { FbDriverHealth, FbRestaurantOrder, FirebaseConstants, Order, OrderPaymentType, OrderStatus, OrderType } from '@tout-ma/general_models';
import { Timestamp, WriteBatch, and, collection, doc, onSnapshot, query, runTransaction, setDoc, where, writeBatch } from 'firebase/firestore';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { firestore } from '../constants/firebase';
import { RestauntContext } from './RestaurantContext';
import { Col, Modal, Row, message } from 'antd';
import { t } from 'i18next';
import { MYCOLORPALETTE } from '../constants/theme';
import CSS from 'csstype';
import ReactHowler from 'react-howler';
import NewOrderMap from '../components/googleMaps/NewOrderMap';
import { getDbOrderApi } from '../api/orders';
import { subDays } from 'date-fns';

type OrderContextType = {
    FbOrders: FbRestaurantOrder[];
    FbDrivers: FbDriverHealth[];
    DbOrders: Order[];
    changeOrderStatus?: (orderId: string, time?: number, newStatus?: OrderStatus) => void;
    endDriverShift?: (driverHealth: FbDriverHealth) => void;
    assignOrdersToDriver?: (driverId: number, orderIds: FbRestaurantOrder[]) => Promise<void>;
};
export const OrdersContext = createContext<OrderContextType>({ FbOrders: [], DbOrders: [], FbDrivers: [] });

export const OrdersContextProvider = ({ children }: { children: React.ReactNode | React.ReactNode[] }) => {
    const [FbOrders, setFbOrders] = useState<FbRestaurantOrder[]>([]);
    const [FbDrivers, setFbDrivers] = useState<FbDriverHealth[]>([]);
    const [DbOrders, setDbOrders] = useState<Order[]>([]);
    const [NewFbOrder, setNewFbOrder] = useState<FbRestaurantOrder>();
    const { Restaurant, loadDbRestaurantDrivers } = useContext(RestauntContext);
    const [Route, setRoute] = useState<google.maps.DirectionsLeg | undefined>();

    const assignOrdersToDriver = async (driverId: number, orders: FbRestaurantOrder[]) => {
        const batch = writeBatch(firestore);

        for (let index = 0; index < orders.length; index++) {
            const element = orders[index];
            if (!element.id) continue;
            const docref = doc(firestore, FirebaseConstants.ORDERS, element.id);
            batch.set(docref, { driver_id: driverId, status: OrderStatus.ONDELIVERY }, { merge: true });
        }
        await batch.commit();
    };

    const changeOrderStatus = (orderId: string, time?: number, newStatus?: OrderStatus) => {
        if (time) {
            const docRef = doc(firestore, FirebaseConstants.ORDERS, orderId);
            setDoc(docRef, { status: OrderStatus.CONFIRMED, estimatedTimeMinutes: time }, { merge: true });
        } else if (newStatus) {
            const docRef = doc(firestore, FirebaseConstants.ORDERS, orderId);
            setDoc(docRef, { status: newStatus }, { merge: true });
        }
    };

    const endDriverShift = (driverHealth: FbDriverHealth) => {
        try {
            if (!driverHealth.id) return;
            const docRef = doc(firestore, FirebaseConstants.DRIVERHEALTH, driverHealth.id);
            setDoc(docRef, { end_shift: new Date(), shift_ended: true }, { merge: true });
        } catch (error) {
            message.error(t('Error ending shift'));
        }
    };

    const fetchDbOrder = async (id: number) => {
        if (DbOrders.some((order) => order.id === id)) return;
        try {
            let order = await getDbOrderApi(id);

            setDbOrders((prev) => [...prev, order]);
        } catch (error) {}
    };

    useEffect(() => {
        if (FbOrders.length && FbOrders.some((order) => order.status === OrderStatus.NEW)) {
            let newOrder = FbOrders.filter((order) => order.status === OrderStatus.NEW)[0];
            setNewFbOrder(newOrder);
        } else if (NewFbOrder) {
            setNewFbOrder(undefined);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [FbOrders]);

    useEffect(() => {
        let unsubscribe: any;
        let date = new Date();
        console.log(date.getHours());
        if (-1 < date.getHours() && date.getHours() < 5) {
            date = subDays(date, 1);
        }
        date.setHours(6, 0, 0, 0);

        if (Restaurant?.id) {
            const q = query(collection(firestore, FirebaseConstants.ORDERS), and(where('restaurant_id', '==', Restaurant?.id), where('created_on', '>', Timestamp.fromDate(date))));
            unsubscribe = onSnapshot(q, (querySnapshot) => {
                querySnapshot.docChanges().forEach((change) => {
                    if (change.type === 'added') {
                        fetchDbOrder(change.doc.data().order_id);
                        console.log('New city: ', change.doc.data());
                    }
                    if (change.type === 'modified') {
                        console.log('Modified city: ', change.doc.data());
                    }
                    if (change.type === 'removed') {
                        console.log('Removed city: ', change.doc.data());
                    }
                });
                const orders: FbRestaurantOrder[] = [];
                querySnapshot.forEach((doc) => {
                    orders.push({ ...doc.data(), id: doc.id } as FbRestaurantOrder);
                });
                setFbOrders(orders);
            });
        }

        return () => unsubscribe();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [Restaurant]);
    useEffect(() => {
        let unsubscribe: any;

        if (Restaurant?.id) {
            const q = query(collection(firestore, FirebaseConstants.DRIVERHEALTH), and(where('restaurant_id', '==', Restaurant?.id), where('shift_ended', '==', false)));
            unsubscribe = onSnapshot(q, (querySnapshot) => {
                querySnapshot.docChanges().forEach((change) => {
                    if (change.type === 'added') {
                        loadDbRestaurantDrivers?.();
                        console.log('New city: ', change.doc.data());
                    }
                    if (change.type === 'modified') {
                        console.log('Modified city: ', change.doc.data());
                    }
                    if (change.type === 'removed') {
                        console.log('Removed city: ', change.doc.data());
                    }
                });
                const drivers: FbDriverHealth[] = [];
                querySnapshot.forEach((doc) => {
                    drivers.push({ ...doc.data(), id: doc.id } as FbDriverHealth);
                });
                setFbDrivers(drivers);
            });
        }

        return () => unsubscribe();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [Restaurant]);

    return (
        <OrdersContext.Provider value={{ FbOrders, changeOrderStatus, DbOrders, FbDrivers, endDriverShift, assignOrdersToDriver }}>
            <Modal
                closable={false}
                width={'70vw'}
                style={{ top: 50 }}
                open={!!NewFbOrder}
                title={NewFbOrder?.type === OrderType.DELIVERY ? t('New Delivery') : t('New Pickup')}
                footer={
                    <div
                        style={{
                            width: '100%',
                            height: '120px',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'flex-start',
                            borderRadius: '10px',
                            border: `2px solid ${MYCOLORPALETTE.FIRST}`,
                        }}
                    >
                        <h3 style={{ margin: '0 0 20px 0', padding: 0 }}>{t('Estimated time in minutes')}</h3>
                        <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center' }}>
                            <div onClick={() => changeOrderStatus(NewFbOrder?.id ?? '', 15)} style={styles.confirmTimeDiv}>
                                <b style={styles.time}>15</b>
                            </div>
                            <div onClick={() => changeOrderStatus(NewFbOrder?.id ?? '', 30)} style={styles.confirmTimeDiv}>
                                <b style={styles.time}>30</b>
                            </div>
                            <div onClick={() => changeOrderStatus(NewFbOrder?.id ?? '', 40)} style={styles.confirmTimeDiv}>
                                <b style={styles.time}>40</b>
                            </div>
                            <div onClick={() => changeOrderStatus(NewFbOrder?.id ?? '', 50)} style={styles.confirmTimeDiv}>
                                <b style={styles.time}>50</b>
                            </div>
                            <div onClick={() => changeOrderStatus(NewFbOrder?.id ?? '', 60)} style={styles.confirmTimeDiv}>
                                <b style={styles.time}>60</b>
                            </div>
                            <div onClick={() => changeOrderStatus(NewFbOrder?.id ?? '', 75)} style={styles.confirmTimeDiv}>
                                <b style={styles.time}>75</b>
                            </div>
                            <div onClick={() => changeOrderStatus(NewFbOrder?.id ?? '', 90)} style={styles.confirmTimeDiv}>
                                <b style={styles.time}>110</b>
                            </div>
                        </div>
                    </div>
                }
            >
                <div style={NewFbOrder?.type === OrderType.DELIVERY ? { height: '70vh' } : { height: '40vh' }}>
                    <div style={{ display: 'flex', justifyContent: 'flex-start', flexDirection: 'column' }}>
                        <Row style={{ margin: 0 }}>
                            <Col span={6} offset={Route ? 0 : 6}>
                                <h5 style={styles.orderItem}>{t('Total price: ')}</h5>
                            </Col>
                            <Col span={6}>
                                <h3 style={styles.orderItem}>{NewFbOrder?.total_price} MAD</h3>
                            </Col>
                            {Route ? (
                                <React.Fragment>
                                    <Col span={6}>
                                        <h5 style={styles.orderItem}>{t('Distance: ')}</h5>
                                    </Col>
                                    <Col span={6}>
                                        <h3 style={styles.orderItem}>{Route?.distance?.text} </h3>
                                    </Col>
                                </React.Fragment>
                            ) : null}
                        </Row>
                        <Row style={{ margin: 0 }}>
                            <Col span={6} offset={Route ? 0 : 6}>
                                <h5 style={styles.orderItem}>{t('Payment: ')}</h5>
                            </Col>
                            <Col span={6}>
                                <h3 style={styles.orderItem}>{NewFbOrder?.payment_type === OrderPaymentType.ONLINE ? t('Online') : t('Cash')} </h3>
                            </Col>
                            {Route ? (
                                <React.Fragment>
                                    <Col span={6}>
                                        <h5 style={styles.orderItem}>{t('Drive time: ')}</h5>
                                    </Col>
                                    <Col span={6}>
                                        <h3 style={styles.orderItem}>{Route?.duration?.text}</h3>
                                    </Col>
                                </React.Fragment>
                            ) : null}
                        </Row>
                        <h4 style={{ margin: '0', textAlign: 'center', width: '100%' }}>Order: (to do)</h4>

                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                            <p>To do</p>
                        </div>

                        {NewFbOrder?.type === OrderType.DELIVERY ? (
                            <div
                                style={{
                                    height: '100%',
                                    width: '100%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    border: '2px solid black',
                                    marginTop: '20px',
                                }}
                            >
                                <div style={{ height: '50vh', width: '100%' }}>
                                    <NewOrderMap order_Lat={NewFbOrder.order_Lat} order_Lng={NewFbOrder.order_Lng} setRoute={setRoute} />
                                </div>
                            </div>
                        ) : null}
                    </div>
                </div>
            </Modal>
            {children}
            <ReactHowler src="notification.mp3" format={['mp3']} playing={!!NewFbOrder} loop={true} preload={true} />
        </OrdersContext.Provider>
    );
};

const styles: { [k: string]: CSS.Properties } = {
    confirmTimeDiv: {
        width: '70px',
        height: '60px',
        borderRadius: '10px',
        border: `2px solid ${MYCOLORPALETTE.BLACK}`,
        backgroundColor: MYCOLORPALETTE.FIRST,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'grab',
    },
    time: {
        color: MYCOLORPALETTE.LIGHTWHITE,
        fontSize: '26px',
        textAlign: 'center',
    },
    orderItem: {
        margin: 0,
        padding: 0,
    },
    quantity: { fontWeight: 'bold', textAlign: 'center', padding: '10px' },
    description: { fontSize: '8px' },
    price: { fontSize: '20px' },
};
