import { Badge, Button, Card, Col, Collapse, Divider, Row, Space, Table, TableColumnsType, Tag, message } from 'antd';
import { t } from 'i18next';
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { RestauntContext } from '../../contexts/RestaurantContext';
import DragSorting from '../../components/dragSorting/DragSorting';
import Title from 'antd/es/typography/Title';
import RestaurantMenuCategory from '@tout-ma/general_models/dist/global/RestaurantMenuCategory';
import { DefaultLocales, RestaurantDish, RestaurantDishOption } from '@tout-ma/general_models';
import i18n from '../../constants/i18n';
import { EditFilled } from '@ant-design/icons';
import RestaurantDishRequiredOption from '@tout-ma/general_models/dist/global/RestaurantDishRequiredOptions';
import { restaurantDishStockApi } from '../../api/dishes';
const MenuOverviewScreen = () => {
    const { Restaurant, reloadRestaurant } = useContext(RestauntContext);
    const navigate = useNavigate();

    const onStockPressed = async (rdId: number) => {
        try {

            await restaurantDishStockApi(rdId)
            message.success('Stock status updated');
            reloadRestaurant?.()
            
            
        } catch (error) {
            message.error('Error while updating stock status');
        }
    };

    const expandedCategorieToDishesRowRender = (menuCat: RestaurantMenuCategory) => {
        const dishColumns: TableColumnsType<RestaurantDish> = [
            {
                title: 'Active',
                key: 'active',
                render: (rd: RestaurantDish) => <Badge status={rd?.active ? 'success' : 'error'} text={rd?.active ? 'Active' : 'Out Of Stock'} />,
            },
            { title: 'Name', key: 'name', render: (rd: RestaurantDish) => <span>{rd.name[rd.name.originalLang as DefaultLocales]}</span> },
            { title: 'Price', key: 'price', render: (rd: RestaurantDish) => <span>{rd.basePrice}</span> },
            { title: '# req options', key: 'reqOptions', render: (rd: RestaurantDish) => <span>{rd.requiredOptions?.length ?? 0}</span> },
            { title: '# base options', key: 'baseOptions', render: (rd: RestaurantDish) => <span>{rd.baseOptions?.length ?? 0}</span> },
            { title: '# paid options', key: 'paidOptions', render: (rd: RestaurantDish) => <span>{rd.extraOptions?.length ?? 0}</span> },
            {
                title: '',
                key: 'operation',
                render: (rd: RestaurantDish) => (
                    <span>
                        <Button type="dashed" icon={<EditFilled />} size="small" style={{ marginRight: '15px' }}>
                            Edit
                        </Button>
                        <Button
                            danger={rd?.active}
                            onClick={() => onStockPressed(rd.id)}
                            style={!rd?.active ? { color: 'white', fontWeight: 'bold', background: 'green' } : {}}
                            size="small"
                        >
                            {rd?.active ? 'Out Of Stock?' : 'Activate'}
                        </Button>
                    </span>
                ),
            },
        ];
        const data = Restaurant?.dishes?.filter((x) => x.menuCategoryId === menuCat.id) ?? [];

        return (
            <Card style={{ border: '1px solid grey' }}>
                <Table
                    columns={dishColumns}
                    dataSource={data}
                    pagination={false}
                    //  expandable={{ expandedRowRender: expandedDishToOptionsRowRender, expandRowByClick: true }}
                />
            </Card>
        );
    };

    const menuColumns: TableColumnsType<RestaurantMenuCategory> = [
        { title: 'Name', key: 'name', render: (rmc: RestaurantMenuCategory) => <span>{rmc.name[rmc.name.originalLang as DefaultLocales]}</span> },
        { title: '# Items', key: 'items', render: (rmc: RestaurantMenuCategory) => <span>{Restaurant?.dishes?.filter((x) => x.menuCategoryId === rmc.id)?.length}</span> },
    ];

    return (
        <Card title={t('Menu Settings')} style={{ margin: 20 }}>
            <Row style={{ alignItems: 'center', marginTop: '10px' }}>
                <Col span={6} offset={5}>
                    <Button block style={{ overflow: 'hidden' }} onClick={() => navigate('menucategories')}>
                        {t('Change Menu Order')}
                    </Button>
                </Col>
                <Col span={6} offset={2}>
                    <Button block style={{ overflow: 'hidden' }} disabled={!Restaurant?.menuCategories?.length} onClick={() => navigate('newmenuitem')}>
                        {t('Add new Menu Item')}
                    </Button>
                </Col>
            </Row>
            <Divider />
            <Row style={{ alignItems: 'center' }}>
                <Col span={24}>
                    <Card>
                        {Restaurant && Restaurant?.menuCategories?.length ? (
                            <Table
                                columns={menuColumns}
                                expandable={{ expandedRowRender: expandedCategorieToDishesRowRender, expandRowByClick: true }}
                                dataSource={Restaurant?.menuCategories ?? []}
                                size="small"
                                pagination={false}
                            />
                        ) : (
                            <span style={{ display: 'flex', justifyContent: 'center', color: 'red' }}>{t('Menu Categories Not Configured yet!')}</span>
                        )}
                    </Card>
                </Col>
            </Row>
            <Divider />
        </Card>
    );
};

export default MenuOverviewScreen;
