import { AdvancedMarker, Map, Marker, Pin } from '@vis.gl/react-google-maps';
import { Button, Card, Col, Divider, Modal, Radio, RadioChangeEvent, Row, Select, Table, TableProps, Tag } from 'antd';
import { t } from 'i18next';
import React, { useContext, useEffect, useState } from 'react';
import { RestauntContext } from '../../contexts/RestaurantContext';
import RestaurantMarker from '../../components/googleMaps/googleMapsComponents';
import { OrdersContext } from '../../contexts/OrdersContext';
import { MYCOLORPALETTE } from '../../constants/theme';
import { FbDriverHealth, FbRestaurantOrder, OrderPaymentType, OrderStatus, OrderType } from '@tout-ma/general_models';
import { Timestamp } from 'firebase/firestore';
import { LogoutOutlined } from '@ant-design/icons';
import { getOrderColor } from '../../constants/orderConstants';
import { format } from 'date-fns';

const MapScreen = () => {
    const { Restaurant, DbRestaurantDrivers } = useContext(RestauntContext);
    const { FbDrivers, FbOrders, endDriverShift, assignOrdersToDriver } = useContext(OrdersContext);
    const [currDateObj, setcurrDateObj] = useState<Date>(new Date());
    const [OrderFilter, setOrderFilter] = useState<'INSTORE' | 'DELIVERY' | 'ALL'>('INSTORE');
    const [DriverToAssignNewDeliveries, setDriverToAssignNewDeliveries] = useState<number>();
    const optionsWithDisabled = [
        { label: t('store'), value: 'INSTORE' },
        { label: t('+ delivery'), value: 'DELIVERY' },
        { label: t('+ delivered'), value: 'ALL' },
    ];
    const [SelectedOrderToAssign, setSelectedOrderToAssign] = useState<FbRestaurantOrder[]>([]);

    const assignDriverToOrderBtnPressed = async () => {
        try {
            console.log(DriverToAssignNewDeliveries, SelectedOrderToAssign);
            if (!DriverToAssignNewDeliveries || !SelectedOrderToAssign?.length) return;
            await assignOrdersToDriver?.(DriverToAssignNewDeliveries, SelectedOrderToAssign);
        } catch (error) {
            console.log(error);
        } finally {
            setSelectedOrderToAssign([]);
            setDriverToAssignNewDeliveries(undefined);
        }
    };

    useEffect(() => {
        const dateObjRefresh = setInterval(() => {
            setcurrDateObj((e) => new Date());
        }, 20000);

        return () => clearTimeout(dateObjRefresh);
    }, []);
    const showLogoutDriverModal = (dbHealth: FbDriverHealth) => {
        let driver = DbRestaurantDrivers?.find((x) => x.id === dbHealth.driver_id);
        if (!driver) return;
        try {
            Modal.confirm({
                title: t(`End a shift?`),
                okText: t('End shift'),
                cancelText: t('Cancel'),
                onOk: async () => {
                    await endDriverShift?.(dbHealth);
                },
                content: (
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column',
                            width: '300px',
                            height: '100px',
                        }}
                    >
                        <b style={{ marginBottom: '10px' }}>{t(`Are you sure to end the shift for ${driver.firstName} ${driver.lastName}?`)}</b>
                    </div>
                ),
            });
        } catch (error: any) {
            console.log(error);
        }
    };

    const columns: TableProps<FbDriverHealth>['columns'] = [
        {
            title: t('Name'),
            dataIndex: 'driver_id',
            key: 'driver_id',
            render: (id) => <b>{`${DbRestaurantDrivers?.find((x) => x.id === id)?.firstName} ${DbRestaurantDrivers?.find((x) => x.id === id)?.lastName?.slice(0, 1)}.`}</b>,
        },
        {
            title: 'Live',
            render: (_, { last_ping }: { last_ping: Timestamp }) => {
                const lastPing = last_ping.toDate();
                const diff = currDateObj.getTime() - lastPing.getTime();
                const seconds = Math.floor(diff / 1000);

                return (
                    <Tag color={seconds < 30 ? MYCOLORPALETTE.FOURTH : seconds < 60 ? MYCOLORPALETTE.THIRD : MYCOLORPALETTE.SECOND}>
                        {seconds < 30 ? t('< 30s') : seconds < 60 ? t('< 60') : t('Offline')}
                    </Tag>
                );
            },
        },
        {
            title: t('End Shift'),
            render: (_, fbhealth) => (
                <Button size="middle" onClick={() => showLogoutDriverModal(fbhealth)}>
                    <LogoutOutlined />
                </Button>
            ),
        },
    ];
    const table1Columns: TableProps<FbRestaurantOrder>['columns'] = [
        {
            title: '#',
            width: 20,
            align: 'center',
            render(value, record, index) {
                return index + 1;
            },
        },
        {
            title: t('Created'),
            dataIndex: 'created_on',
            key: 'id',
            align: 'center',
            render: (created_on: Timestamp) => <b>{format(created_on.toDate(), 'HH:mm')}</b>,
        },
        {
            title: t('Total'),
            dataIndex: 'total_price',
            align: 'center',
            render: (total_price: number) => <b>{total_price} MAD</b>,
        },
        {
            title: t('Payment'),
            dataIndex: 'payment_type',
            align: 'center',
            render: (payment: OrderPaymentType) =>
                payment === OrderPaymentType.ONLINE ? (
                    <Tag color={MYCOLORPALETTE.FOURTH} style={{ fontWeight: 'bold' }}>
                        {t('Online')}
                    </Tag>
                ) : (
                    <Tag color={MYCOLORPALETTE.SECOND} style={{ fontWeight: 'bold' }}>
                        {t('Cash')}
                    </Tag>
                ),
        },
    ];

    const onChange4 = ({ target: { value } }: RadioChangeEvent) => {
        setOrderFilter(value);
    };

    return (
        <Card title={t('Map')} style={{ margin: 20, height: '100%' }}>
            <Row>
                <Col style={{ width: '100%', height: '900px', border: '1px solid black' }} span={15}>
                    <Map
                        defaultCenter={{ lat: Restaurant?.lat ?? 0, lng: Restaurant?.lng ?? 0 }}
                        defaultZoom={12}
                        gestureHandling={'greedy'}
                        disableDefaultUI={true}
                        id="overviewmap"
                        mapId="overviewmap"
                    >
                        {FbDrivers.filter((x) => x.lat && x.lng)?.map((driver) => (
                            <AdvancedMarker key={driver.driver_id} position={{ lat: driver.lat, lng: driver.lng }}>
                                <Pin background={MYCOLORPALETTE.FIRST} borderColor={MYCOLORPALETTE.FIRST} scale={2}>
                                    <span style={{ color: MYCOLORPALETTE.LIGHTWHITE }}>
                                        {/* children are rendered as 'glyph' of pin */}
                                        {`${DbRestaurantDrivers?.find((x) => x.id === driver.driver_id)?.firstName.slice(0, 1)}. ${DbRestaurantDrivers?.find(
                                            (x) => x.id === driver.driver_id
                                        )?.lastName?.slice(0, 1)}.`}
                                    </span>
                                </Pin>
                            </AdvancedMarker>
                        ))}
                        {FbOrders.filter((x) => x.type === OrderType.DELIVERY && ![OrderStatus.CANCELED, OrderStatus.DELIVERED].includes(x.status))?.map((order, index) => (
                            <AdvancedMarker key={order.id} position={{ lat: order?.order_Lat || Restaurant!.lat, lng: order.order_Lng || Restaurant!.lng }}>
                                <Pin background={getOrderColor(order.status)} borderColor={getOrderColor(order.status)} scale={1.4}>
                                    <span style={{ color: MYCOLORPALETTE.LIGHTWHITE }}>{index + 1}</span>
                                </Pin>
                            </AdvancedMarker>
                        ))}
                        <AdvancedMarker position={{ lat: Restaurant?.lat ?? 0, lng: Restaurant?.lng ?? 0 }}>
                            <RestaurantMarker />
                        </AdvancedMarker>
                    </Map>
                </Col>
                <Col style={{ width: '100%', height: '900px', border: '1px solid black' }} span={5}>
                    <Row>
                        <Col offset={1} span={22}>
                            <h3>{t('Orders')}</h3>
                        </Col>
                    </Row>

                    <Row>
                        <Col offset={1} span={22}>
                            <Radio.Group options={optionsWithDisabled} onChange={onChange4} value={OrderFilter} optionType="button" buttonStyle="solid" />
                        </Col>
                    </Row>
                    <Divider style={{ margin: 10 }} type="horizontal" />
                    <Row>
                        <Col offset={1} span={22}>
                            <div
                                style={{
                                    border: `1px solid ${MYCOLORPALETTE.FIRST}`,
                                    borderRadius: 5,
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-evenly',
                                    alignItems: 'center',
                                    padding: '5px 0 ',
                                }}
                            >
                                <Tag color={getOrderColor(OrderStatus.NEW)}>{OrderStatus.NEW}</Tag>
                                <Tag color={getOrderColor(OrderStatus.CONFIRMED)}>{OrderStatus.CONFIRMED}</Tag>
                                <Tag color={getOrderColor(OrderStatus.PREPARING)}>{OrderStatus.PREPARING}</Tag>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Table
                                columns={table1Columns}
                                pagination={false}
                                rowSelection={{
                                    type: 'checkbox',
                                    onChange: (_, selectedRows: FbRestaurantOrder[]) => {
                                        setSelectedOrderToAssign(selectedRows);
                                        if (!DriverToAssignNewDeliveries && selectedRows.length > 0) {
                                            setDriverToAssignNewDeliveries(FbDrivers.filter((x) => !FbOrders.some((y) => y.driver_id === x.driver_id))?.[0].driver_id);
                                        } else if (DriverToAssignNewDeliveries && selectedRows.length === 0) {
                                            setDriverToAssignNewDeliveries(undefined);
                                        }
                                    },
                                }}
                                dataSource={FbOrders.filter(
                                    (x) => x.type === OrderType.DELIVERY && [OrderStatus.NEW, OrderStatus.CONFIRMED, OrderStatus.PREPARING].includes(x.status)
                                )}
                            />
                        </Col>
                    </Row>
                    {SelectedOrderToAssign?.length > 0 ? (
                        <React.Fragment>
                            <div style={{ margin: '5px 20px' }}>
                                <Select
                                    size={'large'}
                                    style={{ width: '100%' }}
                                    value={DriverToAssignNewDeliveries}
                                    onChange={(e) => setDriverToAssignNewDeliveries(e)}
                                    options={FbDrivers.filter((x) => !FbOrders.some((y) => y.driver_id === x.driver_id)).map((z) => ({
                                        value: z.driver_id,
                                        label: `${DbRestaurantDrivers?.find((x) => x.id === z.driver_id)?.firstName} ${DbRestaurantDrivers?.find(
                                            (x) => x.id === z.driver_id
                                        )?.lastName?.slice(0, 1)}.`,
                                    }))}
                                />
                            </div>
                            <Row>
                                <Col offset={6} span={12}>
                                    <Button
                                        type="primary"
                                        onClick={assignDriverToOrderBtnPressed}
                                        disabled={SelectedOrderToAssign?.length === 0}
                                        style={{
                                            width: '100%',
                                            marginTop: 10,
                                            backgroundColor: SelectedOrderToAssign?.length === 0 ? MYCOLORPALETTE.LIGHTGREY : MYCOLORPALETTE.FIRST,
                                        }}
                                    >
                                        <b style={{ color: MYCOLORPALETTE.LIGHTWHITE }}>{t('Assign to driver')}</b>
                                    </Button>
                                </Col>
                            </Row>
                        </React.Fragment>
                    ) : null}

                    {OrderFilter === 'ALL' || OrderFilter === 'DELIVERY' ? (
                        <React.Fragment>
                            <Row>
                                <Col offset={1} span={22}>
                                    <div
                                        style={{
                                            border: `1px solid ${MYCOLORPALETTE.FIRST}`,
                                            borderRadius: 5,
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'space-evenly',
                                            alignItems: 'center',
                                            padding: '5px 0 ',
                                        }}
                                    >
                                        <Tag color={getOrderColor(OrderStatus.ONDELIVERY)}>{OrderStatus.ONDELIVERY}</Tag>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    {FbOrders.filter((x) => x.type === OrderType.DELIVERY && [OrderStatus.ONDELIVERY].includes(x.status)).length > 0 ? (
                                        <Table
                                            columns={table1Columns}
                                            pagination={false}
                                            dataSource={FbOrders.filter((x) => x.type === OrderType.DELIVERY && [OrderStatus.ONDELIVERY].includes(x.status))}
                                        />
                                    ) : (
                                        <div style={{ height: '50px', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                            <b>X</b>
                                        </div>
                                    )}
                                </Col>
                            </Row>
                        </React.Fragment>
                    ) : null}
                    {OrderFilter === 'ALL' ? (
                        <React.Fragment>
                            <Row>
                                <Col offset={1} span={22}>
                                    <div
                                        style={{
                                            border: `1px solid ${MYCOLORPALETTE.FIRST}`,
                                            borderRadius: 5,
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'space-evenly',
                                            alignItems: 'center',
                                            padding: '5px 0 ',
                                        }}
                                    >
                                        <Tag color={getOrderColor(OrderStatus.DELIVERED)}>{OrderStatus.DELIVERED}</Tag>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    {FbOrders.filter((x) => x.type === OrderType.DELIVERY && [OrderStatus.DELIVERED].includes(x.status)).length > 0 ? (
                                        <Table
                                            columns={table1Columns}
                                            pagination={false}
                                            dataSource={FbOrders.filter((x) => x.type === OrderType.DELIVERY && [OrderStatus.DELIVERED].includes(x.status))}
                                        />
                                    ) : (
                                        <div style={{ height: '50px', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                            <b>X</b>
                                        </div>
                                    )}
                                </Col>
                            </Row>
                        </React.Fragment>
                    ) : null}
                </Col>
                <Col style={{ width: '100%', height: '900px', border: '1px solid black' }} span={4}>
                    <Row>
                        <Col offset={1} span={22}>
                            <h3>{t('Drivers')}</h3>
                        </Col>
                    </Row>
                    <Row>
                        <Col offset={1} span={22}>
                            <Table columns={columns} dataSource={FbDrivers} pagination={false} />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Card>
    );
};

export default MapScreen;
