import { Order } from "@tout-ma/general_models";
import Api from ".";
import env from "../constants/env";

export function getDbOrdersForTodayApi(): Promise<Order[]> {
    return new Promise(async (resolve, reject) => {
        try {
            const { data } = await Api({
                method: 'GET',
                url: `${env.api_base_url}${env.services.orders}/restaurants/v1/today`,
            });
            resolve(data);
            return;
        } catch (error: any) {
            console.error('getDbOrdersForTodayApi', error);
            reject(error);
        }
    });
}

export function getDbOrderApi(id: number): Promise<Order> {
    return new Promise(async (resolve, reject) => {
        try {
            const { data } = await Api({
                method: 'GET',
                url: `${env.api_base_url}${env.services.orders}/restaurants/v1/${id}`,
            });
            resolve(data);
            return;
        } catch (error: any) {
            console.error('getDbOrderApi', error);
            reject(error);
        }
    });
}
export function getHistoryOrdersApi(date: Date, driverId?: number): Promise<Order[]> {
    return new Promise(async (resolve, reject) => {
        try {
            const { data } = await Api({
                method: 'POST',
                url: `${env.api_base_url}${env.services.orders}/restaurants/v1/history`,
                data: { date, driverId }
            });
            resolve(data);
            return;
        } catch (error: any) {
            console.error('getHistoryOrdersApi', error);
            reject(error);
        }
    });
}
