import { useContext, useEffect } from 'react';
import { RestauntContext } from '../../contexts/RestaurantContext';
import { useNavigate } from 'react-router-dom';
import { Button, Card, Col, Divider, Row } from 'antd';
import OpeningHoursHeader from '../../components/openinghours/OpeningHoursHeader';
import { t } from 'i18next';
import OpeningHoursOverview from '../../components/openinghours/OpeningHoursOverview';
import GoogleMapReact from 'google-map-react';
import RestaurantMarker from '../../components/googleMaps/googleMapsComponents';
import { colours } from './DeliveryAreasScreen';
import { APIProvider, AdvancedMarker, Map, Marker, useMap, useMapsLibrary } from '@vis.gl/react-google-maps';

const SettingsOverviewScreen = () => {
    const { Restaurant } = useContext(RestauntContext);
    const navigate = useNavigate();
    const map = useMap('overvieuw');

    useEffect(() => {
        if (Restaurant && map) {
            Restaurant.restaurantDeliveryAreas.map(
                (area, index) =>
                    new google.maps.Circle({
                        strokeColor: colours[index % colours.length],
                        strokeOpacity: 0.8,
                        strokeWeight: 2,
                        fillColor: colours[index % colours.length],
                        fillOpacity: 0.35,
                        map,
                        center: { lat: area.lat, lng: area.lng },
                        radius: area.radius,
                    })
            );
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [Restaurant, map]);

    return (
        <Card title={t('Restaurant Settings')} style={{ margin: 20 }}>
            {(Restaurant?.openingHours || Restaurant?.deliveryHours) && <OpeningHoursHeader />}
            <Row style={{ alignItems: 'center' }}>
                <Col span={3}>
                    <Button block style={{ overflow: 'hidden' }} onClick={() => navigate('deliveryhours')}>
                        {t('Change Delivery Hours')}
                    </Button>
                </Col>

                {Restaurant?.deliveryHours ? (
                    <OpeningHoursOverview hours={Restaurant.deliveryHours} />
                ) : (
                    <Col span={21} style={{ display: 'flex', justifyContent: 'center', color: 'red' }}>
                        {t('Not Configured yet!')}
                    </Col>
                )}
            </Row>
            <Divider />
            <Row style={{ alignItems: 'center' }}>
                <Col span={3}>
                    <Button block style={{ overflow: 'hidden' }} onClick={() => navigate('pickuphours')}>
                        Change Pick-Up Hours
                    </Button>
                </Col>

                {Restaurant?.openingHours ? (
                    <OpeningHoursOverview hours={Restaurant.openingHours} />
                ) : (
                    <Col span={21} style={{ display: 'flex', justifyContent: 'center', color: 'red' }}>
                        Not Configured yet!
                    </Col>
                )}
            </Row>
            <Divider />
            <Row style={{ alignItems: 'center', textAlign: 'center' }}>
                <Col span={3}>
                    <Button block style={{ overflow: 'hidden' }} onClick={() => navigate('deliveryareas')}>
                        Modify Delivery Area
                    </Button>
                </Col>
                <Col span={1}></Col>

                <Col span={19} style={{ backgroundColor: 'lightgrey', height: '500px' }}>
                    <Map
                        defaultCenter={{ lat: Restaurant?.lat ?? 0, lng: Restaurant?.lng ?? 0 }}
                        defaultZoom={12}
                        gestureHandling={'greedy'}
                        disableDefaultUI={true}
                        id="overvieuw"
                        mapId="overvieuw"
                    >
                        <AdvancedMarker  position={{ lat: Restaurant?.lat ?? 0, lng: Restaurant?.lng ?? 0 }} ><RestaurantMarker/></AdvancedMarker>
                    </Map>
                </Col>
            </Row>
        </Card>
    );
};

export default SettingsOverviewScreen;
