import { Routes, Route } from 'react-router-dom';
import SettingsOverviewScreen from '../screens/settings/SettingsOverviewScreen';
import DeliveryAreasScreen from '../screens/settings/DeliveryAreasScreen';
import WorkingHoursScreen from '../screens/settings/WorkingHoursScreen';
import MenuOverviewScreen from '../screens/menu/MenuOverviewScreen';
import MenuCategoriesScreen from '../screens/menu/MenuCategoriesScreen';
import NewMenuItemScreen from '../screens/menu/NewMenuItemScreen';
import OrdersScreen from '../screens/orders/OrdersScreen';
import OrdersHistoryScreen from '../screens/ordersHistory/OrdersHistoryScreen';
import DriversScreen from '../screens/drivers/DriversScreen';
import MapScreen from '../screens/map/MapScreen';

const AppRoutes = () => {
    return (
        <Routes>
            <Route path="map" element={<MapScreen />} />
            <Route path="drivers" element={<DriversScreen />} />
            <Route path="order-history" element={<OrdersHistoryScreen />} />
            <Route path="settings/deliveryhours" element={<WorkingHoursScreen delivery={true} />} />
            <Route path="settings/pickuphours" element={<WorkingHoursScreen />} />
            <Route path="settings/deliveryareas" element={<DeliveryAreasScreen />} />
            <Route path="settings" element={<SettingsOverviewScreen />} />
            <Route path="menu/newmenuitem" element={<NewMenuItemScreen />} />
            <Route path="menu/menucategories" element={<MenuCategoriesScreen />} />
            <Route path="menu" element={<MenuOverviewScreen />} />
            <Route path="*" element={<OrdersScreen />} />
        </Routes>
    );
};

export default AppRoutes;
