import { Restaurant, OpeningHours } from "@tout-ma/general_models";
import Axios from ".";
import env from "../constants/env";
export function addOpeningHours(
    type: 'delivery' | 'pickup', openingHours:OpeningHours
): Promise<OpeningHours> {
    return new Promise(async (resolve, reject) => {
        try {
            const { data } = await Axios({
                method: 'POST',
                url: `${env.api_base_url}${env.services.restaurants}/v1/owners/openinghours/${type}`,
                data: openingHours
            });

            resolve(data);
            return;
        } catch (error: any) {
            console.error('getRestaurant', error);
            reject(error);
        }
    });
}

export function updateOpeningHours(
    type: 'delivery' | 'pickup', openingHours:OpeningHours
): Promise<OpeningHours> {
    return new Promise(async (resolve, reject) => {
        try {
            const { data } = await Axios({
                method: 'PUT',
                url: `${env.api_base_url}${env.services.restaurants}/v1/owners/openinghours/${type}`,
                data: openingHours
            });

            resolve(data);
            return;
        } catch (error: any) {
            console.error('getRestaurant', error);
            reject(error);
        }
    });
}