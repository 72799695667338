
const MYCOLORPALETTE = {
    FIRST: '#0091DB',
    SECOND: '#C1272D',
    THIRD: '#FEDE00',
    FOURTH: '#006233',
    ALMOND: '#EADDCA',
    BLUE:{
        SHADE1: '#D4D6EC',  
        SHADE2: '#ADBCE7',
        SHADE3: '#79A5E2',
        
    },
    LIGHTLIGHTGREY: '#F2F2F2',
    SUCCESSGREEN: '#00C135',
    LIGHTLIGHTGREEN: '#F5FFEE',
    LIGHTWHITE: '#FAFAFC',
    WHITE:'#FFFFFF',
    BLACK: '#000000',
    DARKGRAY: '#83829A',
    LIGHTGREY: '#C1C0C8',
}
const SIZES = {
    xSmall: 10,
    small: 12,
    medium: 16,
    large: 20,
    xLarge: 24,
    xxLarge: 44,
};

const SHADOWS = {
    small: {
        shadowColor: '#000',
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
        elevation: 2,
    },
    medium: {
        shadowColor: '#000',
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 5.84,
        elevation: 5,
    },
};

export { MYCOLORPALETTE, SIZES, SHADOWS };
