import { RestaurantDish } from "@tout-ma/general_models";
import Api from ".";
import env from "../constants/env";

export function addDish(
    formData: any
): Promise<RestaurantDish> {
    return new Promise(async (resolve, reject) => {
        try {
            console.log(formData.price)
            const { data } = await Api({
                method: 'POST',
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                url: `${env.api_base_url}${env.services.restaurants}/v1/owners/dishes`,
                data: formData

            });

            resolve(data);
            return;
        } catch (error: any) {
            console.error('addDeliveryArea', error);
            reject(error);
        }
    });
}
export function restaurantDishStockApi(
    rdId: number
): Promise<RestaurantDish> {
    return new Promise(async (resolve, reject) => {
        try {
            const { data } = await Api({
                method: 'PUT',
                url: `${env.api_base_url}${env.services.restaurants}/v1/owners/dishes/${rdId}/stock`,
            });

            resolve(data);
            return;
        } catch (error: any) {
            console.error('restaurantDishApi', error);
            reject(error);
        }
    });
}
