import Api from ".";
import env from "../constants/env";
import { DbRestaurantDriver } from "../contexts/RestaurantContext";

export function getDbRestaurantDriversApi(): Promise<DbRestaurantDriver[]> {
    return new Promise(async (resolve, reject) => {
        try {
            const { data } = await Api({
                method: 'GET',
                url: `${env.api_base_url}${env.services.restaurants}/v1/owners/drivers/getdrivers`,
            });
            resolve(data);
            return;
        } catch (error: any) {
            console.error('getDbRestaurantDriversApi', error);
            reject(error);
        }
    });
}

export function changeAccessDbRestaurantDriverApi(driverConfigId:number): Promise<void> {
    return new Promise(async (resolve, reject) => {
        try {
            const { data } = await Api({
                method: 'PUT',
                url: `${env.api_base_url}${env.services.restaurants}/v1/owners/drivers/changeaccess/${driverConfigId}`,
            });
            resolve(data);
            return;
        } catch (error: any) {
            console.error('changeAccessDbRestaurantDriverApi', error);
            reject(error);
        }
    });
}
export function getLinkDriverTokenApi(): Promise<string> {
    return new Promise(async (resolve, reject) => {
        try {
            const { data } = await Api({
                method: 'POST',
                url: `${env.api_base_url}${env.services.restaurants}/v1/owners/drivers/generate-link-token`,
            });
            resolve(data);
            return;
        } catch (error: any) {
            console.error('getLinkDriverTokenApi', error);
            reject(error);
        }
    });
}
