import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import EN from './en/common.json';
import FR from './fr/common.json'
import AR from './ar/common.json'


// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
    en: {
        translation: EN
    },
    fr: {
        translation: FR
    },
    ar: {
        translation: AR
    }
};

const locale = localStorage.getItem('lang') ?? window.navigator.language ?? 'en';

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        compatibilityJSON: 'v3',
        fallbackLng: 'en',
        debug: false,
        initImmediate: false,
        interpolation: {
            escapeValue: false,
        },
        resources,
        lng: locale.substring(0, 2),
        // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
        // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
        // if you're using a language detector, do not define the lng option

    });

export default i18n;