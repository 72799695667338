import React, { useContext, useState } from 'react';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Card, Checkbox, Form, Input } from 'antd';
import { t } from 'i18next';
import { AuthContext } from '../../contexts/AuthContext';

const LoginScreen: React.FC = () => {
    const { signInWithEmail, signInWithToken } = useContext(AuthContext);
    const [UseToken, setUseToken] = useState<boolean>(false);

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '120px' }}>
            <Card title={t('Restaurant Dashboard - Login')} bordered={false} style={{ width: 600, border: '2px solid grey' }}>
                <Form name="loginForm" initialValues={{ remember: true }} onFinish={UseToken ? signInWithToken : signInWithEmail}>
                    {!UseToken ? (
                        <React.Fragment>
                            <Form.Item name="email" rules={[{ required: true, message: t('Please enter your email!'), type: 'email' }]}>
                                <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder={t('Username')} />
                            </Form.Item>
                            <Form.Item name="password" rules={[{ required: true, message: t('Please input your Password!') }]}>
                                <Input prefix={<LockOutlined className="site-form-item-icon" />} type="password" placeholder={t('Password')} />
                            </Form.Item>
                        </React.Fragment>
                    ) : (
                        <Form.Item name="token" rules={[{ required: true, message: t('Please input your Token!') }]}>
                            <Input prefix={<LockOutlined className="site-form-item-icon" />} type="password" placeholder={t('Token')} />
                        </Form.Item>
                    )}

                    <Form.Item>
                        <Button type="primary" htmlType="submit" className="login-form-button">
                            {t('Log in')}
                        </Button>
                        <Button type="link" onClick={() => setUseToken(!UseToken)}>
                            {UseToken ? t('Use Email') : t('Use Token')}
                        </Button>
                    </Form.Item>
                </Form>
            </Card>
        </div>
    );
};

export default LoginScreen;
