import React, { useContext, useEffect, useState } from 'react';
import { RestauntContext } from '../../contexts/RestaurantContext';
import { useNavigate } from 'react-router-dom';
import { Button, Card, Col, Form, InputNumber, Row } from 'antd';
import { t } from 'i18next';
import { APIProvider, AdvancedMarker, Map, Marker, useMap, useMapsLibrary } from '@vis.gl/react-google-maps';
import { ArrowLeftOutlined, PlusSquareOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { RestaurantDeliveryArea } from '@tout-ma/general_models';
import FormItemLabel from 'antd/es/form/FormItemLabel';
import { addDeliveryArea, deleteDeliveryArea } from '../../api/deliveryareas';
import RestaurantMarker from '../../components/googleMaps/googleMapsComponents';

type addingNewArea = {
    state: boolean;
    lat?: number;
    lng?: number;
    radius?: number;
    price?: number;
    minDeliveryOrderAmount?: number;
    id?: number;
};
export const colours = ['#e6194b', '#3cb44b', '#ffe119', '#4363d8', '#f58231', '#911eb4', '#46f0f0', '#f032e6', '#bcf60c', '#fabebe', '#008080'];

const DeliveryAreasScreen = () => {
    const { Restaurant, reloadRestaurant } = useContext(RestauntContext);
    const navigate = useNavigate();
    const [loaded, setloaded] = useState<boolean>(false);
    const map = useMap('drawingMap');
    const drawingLibrary = useMapsLibrary('drawing');
    const [circlesOnMap, setcirclesOnMap] = useState<google.maps.Circle[]>([]);

    useEffect(() => {
        if (Restaurant && map) {
            setcirclesOnMap(
                Restaurant.restaurantDeliveryAreas.map(
                    (area, index) =>
                        new google.maps.Circle({
                            strokeColor: colours[index % colours.length],
                            strokeOpacity: 0.8,
                            strokeWeight: 2,
                            fillColor: colours[index % colours.length],
                            fillOpacity: 0.35,
                            map,
                            center: { lat: area.lat, lng: area.lng },
                            radius: area.radius,
                        })
                )
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [Restaurant, map]);

    const colorNewArea = '#FF0000';

    const [addingNewAreaConfig, setaddingNewAreaConfig] = useState<addingNewArea>({
        state: false,
    });
    const [addingNewAreaCircleObj, setaddingNewAreaCircleObj] = useState<google.maps.Circle>();

    const finalizeNewArea = async () => {
        if (addingNewAreaConfig.lat && addingNewAreaConfig.lng && addingNewAreaConfig.radius && Restaurant) {
            addingNewAreaCircleObj?.setVisible(false);
            let newArea = {
                restaurantId: Restaurant.id,
                lat: addingNewAreaConfig.lat,
                lng: addingNewAreaConfig.lng,
                radius: addingNewAreaConfig.radius,
                price: addingNewAreaConfig.price ?? 0,
                minOrder: addingNewAreaConfig.minDeliveryOrderAmount,
            };

            await addDeliveryArea(newArea);
            await reloadRestaurant?.();
            setaddingNewAreaConfig({ state: false });
        }
    };
    const cancelNewArea = async () => {
        addingNewAreaCircleObj?.setVisible(false);
        setaddingNewAreaConfig({ state: false });
    };

    const deleteArea = async () => {
        if (addingNewAreaConfig.id) {
            await deleteDeliveryArea(addingNewAreaConfig.id);
            setaddingNewAreaConfig({ state: false });
            circlesOnMap.forEach((circle) => circle.setVisible(false));
            setcirclesOnMap([]);
        }
        reloadRestaurant?.();
    };
    useEffect(() => {
        if (drawingLibrary && map && !loaded) {
            setloaded(true);
            const drawer = new drawingLibrary.DrawingManager({
                drawingMode: null,
                drawingControl: true,
                drawingControlOptions: {
                    position: google.maps.ControlPosition.TOP_CENTER,
                    drawingModes: [google.maps.drawing.OverlayType.CIRCLE],
                },

                circleOptions: {
                    fillColor: '#e6beff',
                    fillOpacity: 0.5,
                    strokeWeight: 5,
                    clickable: false,
                    editable: false,
                    zIndex: 1,
                },
            });
            drawer.setMap(map);
            google.maps.event.addListener(drawer, 'circlecomplete', (circle: google.maps.Circle) => {
                circle.setOptions({ strokeColor: colorNewArea, strokeOpacity: 0.8, strokeWeight: 2, fillColor: colorNewArea, fillOpacity: 0.35 });
                setaddingNewAreaCircleObj(circle);
                setaddingNewAreaConfig({
                    state: true,
                    lat: circle.getCenter()?.lat(),
                    lng: circle.getCenter()?.lng(),
                    radius: Math.round(circle.getRadius()),
                    price: 0,
                    minDeliveryOrderAmount: Restaurant?.minDeliveryOrderAmount ?? 0,
                });
                drawer?.setDrawingMode(null);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [drawingLibrary, map]);

    return (
        <Card
            title={
                <span>
                    <Button icon={<ArrowLeftOutlined />} style={{ marginRight: 10 }} title={t('Go Back')} onClick={() => navigate('/settings')} /> {t('Delivery Areas')}
                </span>
            }
            style={{ margin: 20 }}
        >
            <Row style={{ alignItems: 'center', textAlign: 'center' }}>
                <Col span={18} style={{ backgroundColor: 'lightgrey', height: '600px' }}>
                    <Map
                        defaultCenter={{ lat: Restaurant?.lat ?? 0, lng: Restaurant?.lng ?? 0 }}
                        defaultZoom={12}
                        gestureHandling={'greedy'}
                        disableDefaultUI={true}
                        id="drawingMap"
                        mapId={'drawingMap'}
                    >
                        <AdvancedMarker position={{ lat: Restaurant?.lat ?? 0, lng: Restaurant?.lng ?? 0 }}>
                            <RestaurantMarker />
                        </AdvancedMarker>
                    </Map>
                </Col>
                <Col span={6} style={{ padding: 5, border: '1px solid grey', height: '600px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                    <Card title={t('Delivery Areas')} style={{ height: '100%', padding: 0, margin: 0 }}>
                        {Restaurant?.restaurantDeliveryAreas.length === 0 && !addingNewAreaConfig ? (
                            <React.Fragment>
                                <b>{t('No Areas Yet!')}</b>
                                <p>{t('Please add areas')}</p>
                            </React.Fragment>
                        ) : (
                            ''
                        )}
                        {addingNewAreaConfig.state && (
                            <Card style={{ margin: 0, width: '100%', padding: 0, border: `1px solid ${colorNewArea}` }}>
                                <p style={{ marginBottom: '2px' }}>{t('Radius in meters')}</p>
                                <InputNumber
                                    addonAfter={t('meter')}
                                    style={{ width: '100%' }}
                                    min={100}
                                    value={addingNewAreaConfig.radius}
                                    disabled={addingNewAreaConfig.id ? true : false}
                                    onChange={(val) => {
                                        if (!val) return;
                                        setaddingNewAreaConfig({ ...addingNewAreaConfig, radius: val });
                                        addingNewAreaCircleObj?.setRadius(val);
                                    }}
                                    changeOnWheel
                                />
                                <p style={{ marginBottom: '2px' }}>{t('Extra cost')}</p>
                                <InputNumber
                                    addonAfter={t('MAD')}
                                    style={{ width: '100%' }}
                                    min={0}
                                    disabled={addingNewAreaConfig.id ? true : false}
                                    value={addingNewAreaConfig.price}
                                    onChange={(val) => {
                                        if (!val) return;
                                        setaddingNewAreaConfig({ ...addingNewAreaConfig, price: val });
                                    }}
                                    changeOnWheel
                                />
                                <p style={{ marginBottom: '2px' }}>{t('Min. order amount')}</p>
                                <InputNumber
                                    addonAfter={t('MAD')}
                                    style={{ width: '100%' }}
                                    min={0}
                                    disabled={addingNewAreaConfig.id ? true : false}
                                    value={addingNewAreaConfig.minDeliveryOrderAmount}
                                    onChange={(val) => {
                                        if (!val) return;
                                        setaddingNewAreaConfig({ ...addingNewAreaConfig, minDeliveryOrderAmount: val });
                                    }}
                                    changeOnWheel
                                />

                                {addingNewAreaConfig?.id ? (
                                    <Button danger icon={<DeleteOutlined />} block style={{ marginTop: 10 }} onClick={deleteArea}>
                                        {t('delete Area')}
                                    </Button>
                                ) : (
                                    <Button type="primary" icon={<PlusSquareOutlined />} block style={{ marginTop: 10 }} onClick={finalizeNewArea}>
                                        {t('Add Area')}
                                    </Button>
                                )}
                                <Button block style={{ marginTop: 10 }} onClick={cancelNewArea}>
                                    {t('Cancel')}
                                </Button>
                            </Card>
                        )}
                        {!addingNewAreaConfig.state &&
                            Restaurant?.restaurantDeliveryAreas.map((area, index) => {
                                return (
                                    <Button
                                        onClick={() =>
                                            setaddingNewAreaConfig({
                                                state: true,
                                                lat: area.lat,
                                                lng: area.lng,
                                                radius: Math.round(area.radius),
                                                price: area.price,
                                                minDeliveryOrderAmount: area.minOrder,
                                                id: area.id,
                                            })
                                        }
                                        type="default"
                                        style={{
                                            height: area.price && area.minOrder ? '85px' : '60px',
                                            border: `3px solid ${colours[index % colours.length]}`,
                                            marginLeft:'25px',
                                            marginRight:'25px',
                                            marginBottom: '5px',
                                        }}
                                    >
                                        <p style={{ margin: 0, padding: 0 }}>
                                            {t('Radius')}: {area.radius} {t('meters')}
                                        </p>
                                        {area.price && area.minOrder ? (
                                            <React.Fragment>
                                                <p style={{ margin: 0, padding: 0 }}>
                                                    {t('Extra cost')}: {area.price} MAD
                                                </p>
                                                <p style={{ margin: 0, padding: 0 }}>
                                                    {t('Min. order amount')}: {area.minOrder} MAD
                                                </p>
                                            </React.Fragment>
                                        ) : area.price ? (
                                            <p style={{ margin: 0, padding: 0 }}>
                                                {t('Extra cost')}: {area.price} MAD
                                            </p>
                                        ) : (
                                            <p style={{ margin: 0, padding: 0 }}>
                                                {t('Min. order amount')}: {area.minOrder} MAD
                                            </p>
                                        )}
                                    </Button>
                                );
                            })}
                    </Card>
                </Col>
            </Row>
        </Card>
    );
};

export default DeliveryAreasScreen;
