import React, { useContext, useEffect, useState } from 'react';
import { OrdersContext } from '../../contexts/OrdersContext';
import { DefaultLocales, Order, OrderDish, OrderPaymentType, OrderType, TranslatedText } from '@tout-ma/general_models';
import { Col, Space, List, Row, Tabs, Button, message, Divider, Tag } from 'antd';
import { t } from 'i18next';
import CSS from 'csstype';
import NewOrderMap from '../googleMaps/NewOrderMap';
import { RestauntContext } from '../../contexts/RestaurantContext';
import { getOriginalTranslation } from '../../functions/translatedText';
import { translateText } from '../../api/translate';
import { set } from 'date-fns';
import { MYCOLORPALETTE } from '../../constants/theme';

const OrderModal = ({ dbOrderId, closeModal, HistoryDbOrders }: { dbOrderId: number; closeModal: () => void; HistoryDbOrders?: Order[] }) => {
    const { DbOrders } = useContext(OrdersContext);
    const [CurrentOrder, setCurrentOrder] = useState<Order>();
    const [Route, setRoute] = useState<google.maps.DirectionsLeg | undefined>();
    const { Restaurant } = useContext(RestauntContext);
    const [ShowTranslation, setShowTranslation] = useState<boolean>(false);

    useEffect(() => {
        let currOrder: Order | undefined;
        if (HistoryDbOrders) {
            currOrder = HistoryDbOrders.find((order) => order.id === dbOrderId);
        } else {
            currOrder = DbOrders.find((order) => order.id === dbOrderId);
        }
        if (currOrder) {
            setCurrentOrder(currOrder);
        }
    }, [CurrentOrder, DbOrders, HistoryDbOrders, dbOrderId]);

    const renderDeliveryTab = () => {
        return (
            <div style={{ height: '100%', width: '100%' }}>
                <Row style={{ margin: 0, height: '20%' }}>
                    {Route ? (
                        <React.Fragment>
                            <Col span={6}>
                                <h5 style={styles.orderItem}>{t('Distance: ')}</h5>
                            </Col>
                            <Col span={6}>
                                <h3 style={styles.orderItem}>{Route?.distance?.text} </h3>
                            </Col>
                            <Col span={6}>
                                <h5 style={styles.orderItem}>{t('Drive time: ')}</h5>
                            </Col>
                            <Col span={6}>
                                <h3 style={styles.orderItem}>{Route?.duration?.text}</h3>
                            </Col>
                        </React.Fragment>
                    ) : null}
                </Row>
                <div style={{ width: '100%', height: '60vh', marginTop: '10px' }}>
                    <NewOrderMap order_Lat={CurrentOrder?.destinationLat} order_Lng={CurrentOrder?.destinationLng} setRoute={setRoute} />
                </div>
            </div>
        );
    };

    const renderOrderTab = () => {
        return (
            <div style={{ height: '100%', width: '100%' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <p>{t('Order Details')}</p>
                    <Button onClick={() => setShowTranslation((e) => !e)} style={{ backgroundColor: MYCOLORPALETTE.FIRST, color: MYCOLORPALETTE.LIGHTWHITE, fontWeight: 'bold' }}>
                        {ShowTranslation ? t('Hide translation') : t('Show translation')}
                    </Button>
                </div>
                <div style={{ overflow: 'auto', height: '500px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                    <List
                        itemLayout="horizontal"
                        dataSource={CurrentOrder?.orderdishes}
                        renderItem={(item, index) => {
                            let menuDish = Restaurant?.dishes.find((dish) => dish.id === item.restaurantDishId);
                            console.log({ menuDish, item });
                            return (
                                <List.Item>
                                    <List.Item.Meta
                                        title={`${item.quantity} x ${getOriginalTranslation(menuDish?.name)}`}
                                        description={RenderTranslatedDescription(item.extraInstructions)}
                                    />
                                    <List.Item.Meta description={RenderDishOptions(item)} />

                                    <p>
                                        <b>{item.totalPrice}</b> MAD
                                    </p>
                                </List.Item>
                            );
                        }}
                    />
                    <Row>
                        <Col span={10} offset={1}>
                            <div style={{ display: 'flex', flexDirection: 'column', border: `1px solid ${MYCOLORPALETTE.FIRST}`, borderRadius: 5, alignItems: 'center' }}>
                                {CurrentOrder?.extraInstructions ? <b>{t('Extra order instruction')}</b> : <b>{t('No extra order instruction')}</b>}
                                <Divider style={styles.orderItem} type="horizontal"></Divider>
                                {CurrentOrder?.extraInstructions ? (
                                    <Row style={{ width: '100%', paddingTop: '5px', paddingBottom: '5px' }}>
                                        <Col span={2} offset={1}>
                                            {ShowTranslation ? (
                                                <React.Fragment>
                                                    <p style={styles.orderItem}>{DefaultLocales.EN}:</p>
                                                    <p style={styles.orderItem}>{DefaultLocales.FR}:</p>
                                                    <p style={styles.orderItem}>{DefaultLocales.AR}:</p>
                                                    <p style={styles.orderItem}>{DefaultLocales.ES}:</p>
                                                    <p style={styles.orderItem}>{DefaultLocales.NL}:</p>
                                                </React.Fragment>
                                            ) : (
                                                <p style={styles.orderItem}>{CurrentOrder?.extraInstructions?.originalLang}:</p>
                                            )}
                                        </Col>
                                        <Col span={21}>
                                            {ShowTranslation ? (
                                                <React.Fragment>
                                                    <p style={styles.orderItem}>{CurrentOrder?.extraInstructions?.[DefaultLocales.EN]}</p>
                                                    <p style={styles.orderItem}>{CurrentOrder?.extraInstructions?.[DefaultLocales.FR]}</p>
                                                    <p style={styles.orderItem}>{CurrentOrder?.extraInstructions?.[DefaultLocales.AR]}</p>
                                                    <p style={styles.orderItem}>{CurrentOrder?.extraInstructions?.[DefaultLocales.ES]}</p>
                                                    <p style={styles.orderItem}>{CurrentOrder?.extraInstructions?.[DefaultLocales.NL]}</p>
                                                </React.Fragment>
                                            ) : (
                                                <p style={styles.orderItem}>{getOriginalTranslation(CurrentOrder?.extraInstructions)}</p>
                                            )}
                                        </Col>
                                    </Row>
                                ) : null}
                            </div>
                        </Col>
                        <Col span={10} offset={1}>
                            <div style={{ display: 'flex', flexDirection: 'column', border: `1px solid ${MYCOLORPALETTE.FIRST}`, borderRadius: 5, alignItems: 'center' }}>
                                <b>{t('Payment')}</b>
                                <Divider style={styles.orderItem} type="horizontal"></Divider>
                                <div style={{ width: '60%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <p style={styles.orderItem}>{t('Order price')}</p>
                                    <b style={styles.orderItem}>{CurrentOrder?.orderPrice} MAD</b>
                                </div>
                                {CurrentOrder?.type === OrderType.DELIVERY ? (
                                    <div style={{ width: '60%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <p style={styles.orderItem}>{t('Delivery cost')}</p>
                                        <b style={styles.orderItem}>{CurrentOrder?.deliveryPrice} MAD</b>
                                    </div>
                                ) : null}
                                <div style={{ width: '60%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <p style={styles.orderItem}>{t('Online payment cost')}</p>
                                    <b style={styles.orderItem}>3 MAD</b>
                                </div>
                                <Divider style={styles.orderItem} type="horizontal"></Divider>
                                <div style={{ width: '60%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <p style={styles.orderItem}>{t('Total price')}</p>
                                    <b style={styles.orderItem}>{CurrentOrder?.totalPrice} MAD</b>
                                </div>
                                <Divider style={styles.orderItem} type="horizontal"></Divider>

                                <b style={{ marginTop: '10px' }}>
                                    {t('Payment method')}:{' '}
                                    {CurrentOrder?.paymentType === OrderPaymentType.ONLINE ? (
                                        <Tag color={MYCOLORPALETTE.FOURTH} style={{ fontWeight: 'bold' }}>
                                            {t('Online')}
                                        </Tag>
                                    ) : (
                                        <Tag color={MYCOLORPALETTE.SECOND} style={{ fontWeight: 'bold' }}>
                                            {t('Cash')}
                                        </Tag>
                                    )}
                                </b>
                                {CurrentOrder?.client_pays_with ? (
                                    <b style={styles.orderItem}>
                                        {t('Client pays with')}: {CurrentOrder?.client_pays_with} MAD
                                    </b>
                                ) : null}
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        );
    };

    const RenderTranslatedDescription = (description: Partial<TranslatedText>) => {
        if (!description || !description.originalLang || !(description as any)?.[description?.originalLang] || (description as any)?.[description?.originalLang] === '')
            return null;
        let originalText = (description as any)?.[description?.originalLang];

        return (
            <Row>
                <Col span={2}>
                    {ShowTranslation ? (
                        <React.Fragment>
                            <p style={styles.orderItem}>{DefaultLocales.EN}:</p>
                            <p style={styles.orderItem}>{DefaultLocales.FR}:</p>
                            <p style={styles.orderItem}>{DefaultLocales.AR}:</p>
                            <p style={styles.orderItem}>{DefaultLocales.ES}:</p>
                            <p style={styles.orderItem}>{DefaultLocales.NL}:</p>
                        </React.Fragment>
                    ) : (
                        <p style={styles.orderItem}>{description.originalLang}:</p>
                    )}
                </Col>
                <Col span={16}>
                    {ShowTranslation ? (
                        <React.Fragment>
                            <p style={styles.orderItem}>{description[DefaultLocales.EN]}</p>
                            <p style={styles.orderItem}>{description[DefaultLocales.FR]}</p>
                            <p style={styles.orderItem}>{description[DefaultLocales.AR]}</p>
                            <p style={styles.orderItem}>{description[DefaultLocales.ES]}</p>
                            <p style={styles.orderItem}>{description[DefaultLocales.NL]}</p>
                        </React.Fragment>
                    ) : (
                        <p style={styles.orderItem}>{originalText}</p>
                    )}
                </Col>
            </Row>
        );
    };

    const RenderDishOptions = (dish: OrderDish) => {
        let menuDish = Restaurant?.dishes.find((d) => d.id === dish.restaurantDishId);
        if (!menuDish) return null;
        return (
            <div>
                {dish.selectedRequiredOptions.length ? (
                    <React.Fragment>
                        <b style={styles.orderItem}>{t('Required options')}</b>
                        {dish.selectedRequiredOptions.map((option) => {
                            return (
                                <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                    <p style={styles.orderItem}>
                                        {getOriginalTranslation(menuDish?.requiredOptions?.find((x) => x.id === option.requiredOptionId)?.name)}
                                        {' => '}
                                    </p>
                                    <p style={styles.orderItem}>
                                        {getOriginalTranslation(
                                            menuDish?.requiredOptions
                                                ?.find((x) => x.id === option.requiredOptionId)
                                                ?.requiredOptions?.find((y) => y.id === option.requiredOptionSelectedOptionId)?.name
                                        )}
                                    </p>
                                </div>
                            );
                        })}
                    </React.Fragment>
                ) : null}
                {dish.selectedBaseOptions.length ? (
                    <React.Fragment>
                        <b style={styles.orderItem}>{t('Optionals options')}</b>
                        {dish.selectedBaseOptions.map((option) => {
                            return <p style={styles.orderItem}>{getOriginalTranslation(menuDish?.baseOptions?.find((x) => x.id === option)?.name)}</p>;
                        })}
                    </React.Fragment>
                ) : null}
                {dish.selectedExtraOptions.length ? (
                    <React.Fragment>
                        <b style={styles.orderItem}>{t('Paid options')}</b>
                        {dish.selectedExtraOptions.map((option) => {
                            return <p style={styles.orderItem}>{getOriginalTranslation(menuDish?.extraOptions?.find((x) => x.id === option)?.name)}</p>;
                        })}
                    </React.Fragment>
                ) : null}
            </div>
        );
    };

    const renderClientTab = () => {
        let data = { firstOrderWithTout: true, numberOfOrders: 25, totalAmountSpent: 3849 };
        return (
            <div style={{ overflow: 'auto', height: '500px', paddingTop: '50px', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}>
                <Row style={{ margin: '10px 0' }}>
                    <Col span={4} offset={2}>
                        {t('Client name')}:
                    </Col>
                    <Col span={4}>
                        <b>{CurrentOrder?.clientName}</b>
                    </Col>
                </Row>
                <Row style={{ margin: '10px 0' }}>
                    <Col span={4} offset={2}>
                        {t('Phone number')}:
                    </Col>
                    <Col span={4}>
                        <b>{CurrentOrder?.clientPhone}</b>
                    </Col>
                </Row>
                <Row style={{ margin: '10px 0' }}>
                    <Col span={4} offset={2}>
                        {t('Number of orders')}:
                    </Col>
                    <Col span={4}>
                        <b>{data.numberOfOrders}</b>
                    </Col>
                </Row>
                <Row style={{ margin: '10px 0' }}>
                    <Col span={4} offset={2}>
                        {t('Total amount spent')}:
                    </Col>
                    <Col span={4}>
                        <b>{data.totalAmountSpent} MAD</b>
                    </Col>
                </Row>
                <Row style={{ margin: '20px 0' }}>
                    <Col span={4} offset={2}>
                        {t('Client 1st Tout order')}:
                    </Col>
                    <Col span={4}>
                        {data.firstOrderWithTout ? (
                            <Tag color={MYCOLORPALETTE.SECOND} style={{ fontWeight: 'bold' }}>
                                {t('YES')}
                            </Tag>
                        ) : (
                            <Tag color={MYCOLORPALETTE.FOURTH} style={{ fontWeight: 'bold' }}>
                                {t('NO')}
                            </Tag>
                        )}
                    </Col>
                </Row>
            </div>
        );
    };

    if (!CurrentOrder) return <p>loading...</p>;

    return (
        <div style={{ height: '70vh' }}>
            <Tabs
                defaultActiveKey="ordertab"
                type="card"
                size={'large'}
                items={[
                    ...(CurrentOrder.type === OrderType.DELIVERY
                        ? [
                              {
                                  label: t('Delivery Map'),
                                  key: 'deliverytab',
                                  children: renderDeliveryTab(),
                              },
                          ]
                        : []),
                    {
                        label: t('Order Details'),
                        key: 'ordertab',
                        children: renderOrderTab(),
                    },
                    {
                        label: t('Client info'),
                        key: 'clienttab',
                        children: renderClientTab(),
                    },
                ]}
            />
        </div>
    );
};

export default OrderModal;

const styles: { [k: string]: CSS.Properties } = {
    orderItem: {
        margin: 0,
        padding: 0,
    },
};
