import { Restaurant, RestaurantDeliveryArea } from "@tout-ma/general_models";
import Axios from ".";
import env from "../constants/env";
export function getRestaurant(
    bearerToken?: string
): Promise<Restaurant> {
    return new Promise(async (resolve, reject) => {
        try {
            const { data } = await Axios({
                method: 'GET',
                url: `${env.api_base_url}${env.services.restaurants}/v1/owners/restaurant`,
                ...(bearerToken ? { headers: { Authorization: `Bearer ${bearerToken}` } } : {}),
            });
            resolve(data);
            return;
        } catch (error: any) {
            console.error('getRestaurant', error);
            reject(error);
        }
    });
}

export function update(
    restaurant: Restaurant
): Promise<Restaurant> {
    return new Promise(async (resolve, reject) => {
        try {
            const { data } = await Axios({
                method: 'PUT',
                url: `${env.api_base_url}${env.services.restaurants}/v1/owners/restaurant`,
                data: restaurant
            });
            resolve(data);
            return;
        } catch (error: any) {
            console.error('getRestaurant', error);
            reject(error);
        }
    });
}
