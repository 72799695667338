import { Restaurant } from '@tout-ma/general_models';
import { createContext, useContext, useEffect, useState } from 'react';
import { AuthContext } from './AuthContext';
import LoginScreen from '../screens/auth/LoginScreen';
import { getRestaurant, update } from '../api/restaurants';
import { Alert } from 'antd';
import { t } from 'i18next';
import { getDbRestaurantDriversApi } from '../api/drivers';
export type DbRestaurantDriver = {
    id:number;
    firstName: string;
    lastName: string;
    mobilePhone: string;
    active: boolean;
    driverConfigId: number;
};

type RestaurantContextType = {
    Restaurant?: Restaurant;
    DbRestaurantDrivers?: DbRestaurantDriver[];
    reloadRestaurant?: () => Promise<void>;
    updateRestaurant?: (restaurant: Restaurant) => Promise<void>;
    loadDbRestaurantDrivers?: () => Promise<void>;
};

export const RestauntContext = createContext<RestaurantContextType>({});

export const RestaurantContextProvider = ({ children }: { children: React.ReactNode | React.ReactNode[] }) => {
    const [Restaurant, setRestaurant] = useState<Restaurant>();
    const [errormessage, seterrormessage] = useState<string[]>();
    const [DbRestaurantDrivers, setDbRestaurantDrivers] = useState<DbRestaurantDriver[]>();
    
    const { User, logout } = useContext(AuthContext);

    const reloadRestaurant = async () => {
        try {
            let restaurant = await getRestaurant();
            setRestaurant(restaurant);
        } catch (error) {
            seterrormessage([t('Oops, something went wrong'), t('Please try again or contact support')]);
            logout?.();
        }
    };

    const loadDbRestaurantDrivers = async () => {
        try {
            let drivers = await getDbRestaurantDriversApi();
            setDbRestaurantDrivers(drivers ?? []);
        } catch (error) {
            console.log(error);
        }
    };

    const updateRestaurant = async (restaurant: Restaurant) => {
        try {
            let respons = await update(restaurant);
            setRestaurant(respons);
        } catch (error) {
            console.log(error);
        }
    };
    const loadInRestaurantOnInit = async () => {
        try {
            if (User) {
                const { token } = await User?.getIdTokenResult();
                let restaurant = await getRestaurant(token);
                if (restaurant) {
                    setRestaurant(restaurant);
                    await loadDbRestaurantDrivers();
                } else {
                    seterrormessage([t('Oops, something went wrong'), t('Please try again or contact support')]);
                    logout?.();
                }
            }
        } catch (error) {
            seterrormessage([t('Oops, something went wrong'), t('Please try again or contact support')]);
            logout?.();
        }
    };

    useEffect(() => {
        if (User && !Restaurant) {
            loadInRestaurantOnInit();
        }
        if (!User) {
            setRestaurant(undefined);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [User]);

    if (!User || !Restaurant) return <LoginScreen />;

    return (
        <RestauntContext.Provider value={{ Restaurant, reloadRestaurant, updateRestaurant, DbRestaurantDrivers, loadDbRestaurantDrivers }}>
            {errormessage && errormessage.length ? (
                <Alert message={errormessage[0]} description={errormessage[1] ?? ''} type="error" closable onClose={() => seterrormessage([])} />
            ) : (
                ''
            )}
            {children}
        </RestauntContext.Provider>
    );
};
