import { DefaultLocales, TranslatedText } from "@tout-ma/general_models";
import i18n from "../constants/i18n";

export const getCorrectTranslation = (object?: TranslatedText) => {

    let currentLang = i18n.language as DefaultLocales || DefaultLocales.EN;
    return object?.[currentLang] || '';
}

export const getOriginalTranslation = (object?: TranslatedText) => {
    let originalLang = object?.originalLang as DefaultLocales || DefaultLocales.EN;
    return object?.[originalLang] || '';
}