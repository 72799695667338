import { Restaurant, OpeningHours } from "@tout-ma/general_models";
import Axios from ".";
import RestaurantMenuCategory from "@tout-ma/general_models/dist/global/RestaurantMenuCategory";
import env from "../constants/env";
export function addMenuCategory(
    menucat: Partial<RestaurantMenuCategory>
): Promise<RestaurantMenuCategory> {
    return new Promise(async (resolve, reject) => {
        try {
            const { data } = await Axios({
                method: 'POST',
                url: `${env.api_base_url}${env.services.restaurants}/v1/owners/menucategories`,
                data: menucat
            });

            resolve(data);
        } catch (error: any) {
            console.error('getRestaurant', error);
            reject(error);
        }
    });
}

export function updateMenuCategory(
    menucat: Partial<RestaurantMenuCategory> 
): Promise<RestaurantMenuCategory> {
    return new Promise(async (resolve, reject) => {
        try {
            const { data } = await Axios({
                method: 'PUT',
                url: `${env.api_base_url}${env.services.restaurants}/v1/owners/menucategories/${menucat.id}`,
                data: menucat
            });

            resolve(data);
        } catch (error: any) {
            console.error('getRestaurant', error);
            reject(error);
        }
    });
}
export function deleteMenuCategory(
    id: number
): Promise<void> {
    return new Promise(async (resolve, reject) => {
        try {
            await Axios({
                method: 'DELETE',
                url: `${env.api_base_url}${env.services.restaurants}/v1/owners/menucategories/${id}`,
            });

            resolve();
        } catch (error: any) {
            console.error('getRestaurant', error);
            reject(error);
        }
    });
}
