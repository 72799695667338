import { Image, Layout } from 'antd';
import React from 'react';
import LOGO from './assets/logo.png';
import SideMenuLinks from './navigation/SideMenuLinks';
import AppRoutes from './navigation/AppRoutes';
import './app.css';
const { Sider, Content, Footer } = Layout;

function App() {
    return (
        <Layout>
            <Sider style={{ height: '98vh', backgroundColor: 'white' }}>
                <Image src={LOGO} preview={false} />
                <SideMenuLinks />
            </Sider>
            <Layout>
                <Content>
                    <AppRoutes />
                </Content>
                <Footer style={{ textAlign: 'center' }}>TOUT.ma Restaurant Dashboard ©2024</Footer>
            </Layout>
        </Layout>
    );
}

export default App;
