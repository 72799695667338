import { Button, Card, Col, Divider, Modal, Row, Table, TableColumnsType, Tag } from 'antd';
import { t } from 'i18next';
import React, { useContext, useState } from 'react';
import { OrdersContext } from '../../contexts/OrdersContext';
import { useNavigate } from 'react-router-dom';
import { Order, OrderPaymentType, OrderStatus, OrderType } from '@tout-ma/general_models';
import { format } from 'date-fns';
import { MYCOLORPALETTE } from '../../constants/theme';
import OrderModal from '../../components/modals/OrderModal';
import { PrinterOutlined, CloseOutlined } from '@ant-design/icons';
import { getOrderColor } from '../../constants/orderConstants';

const OrdersScreen = () => {
    const [ShowDbOrderId, setShowDbOrderId] = useState<number | void>();
    const { FbOrders, DbOrders, changeOrderStatus } = useContext(OrdersContext);

    const renderOrderStatus = (id?: number, statusFix?: OrderStatus) => {
        let status;
        if (id) {
            status = FbOrders.find((order) => order.order_id === id)?.status;
        } else {
            status = statusFix;
        }
        let color = getOrderColor(status);
        return <Tag color={color}>{status}</Tag>;
    };
    const tableColums: TableColumnsType<Order> = [
        {
            title: t('Order Time'),
            dataIndex: 'createdOn',
            key: 'key',
            align: 'center',
            render: (date) => format(new Date(date), 'HH:mm'),
        },
        {
            title: t('Type'),
            dataIndex: 'type',
            align: 'center',
            render: (type: OrderType) =>
                type === OrderType.DELIVERY ? (
                    <Tag color={MYCOLORPALETTE.FIRST} style={{ fontWeight: 'bold' }}>
                        {t('Delivery')}
                    </Tag>
                ) : (
                    <Tag color={MYCOLORPALETTE.THIRD} style={{ fontWeight: 'bold' }}>
                        {t('Pickup')}
                    </Tag>
                ),
        },
        {
            title: t('Total price'),
            dataIndex: 'totalPrice',
            align: 'center',
            render: (totalPrice) => (
                <p style={{ margin: 0, padding: 0 }}>
                    <b>{totalPrice}</b> MAD
                </p>
            ),
        },
        {
            title: t('Payment'),
            dataIndex: 'paymentType',
            align: 'center',
            render: (payment: OrderPaymentType) =>
                payment === OrderPaymentType.ONLINE ? (
                    <Tag color={MYCOLORPALETTE.FOURTH} style={{ fontWeight: 'bold' }}>
                        {t('Online')}
                    </Tag>
                ) : (
                    <Tag color={MYCOLORPALETTE.SECOND} style={{ fontWeight: 'bold' }}>
                        {t('Cash')}
                    </Tag>
                ),
        },
        {
            title: 'Status',
            align: 'center',
            render: (order) => renderOrderStatus(order.id),
        },
    ];

    const updateOrderStatus = (status: OrderStatus) => {
        if(!ShowDbOrderId) return;
        let currentOrderDbId = FbOrders.find((x) => x.order_id === ShowDbOrderId)?.id;
        if(!currentOrderDbId) return;
        changeOrderStatus?.(currentOrderDbId,undefined,status);
    }
    const renderModalFooter = () => {
        let currentStatus = FbOrders.find((x) => x.order_id === ShowDbOrderId)?.status;
        let currentOrderType = FbOrders.find((x) => x.order_id === ShowDbOrderId)?.type;
        return (
            <div style={{ width: '100%', height: '100px', border: '1px solid black', display: 'flex', flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center' }}>
                <Button icon={<PrinterOutlined style={{ color: MYCOLORPALETTE.LIGHTWHITE }} />} style={{ backgroundColor: MYCOLORPALETTE.FIRST, height: '60%' }}>
                    <b style={{ color: MYCOLORPALETTE.WHITE }}>{t('Print')}</b>
                </Button>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-around',
                        alignItems: 'center',
                        border: `2px solid ${MYCOLORPALETTE.BLUE}`,
                        height: '60%',
                    }}
                >
                    <Button
                        disabled={true}
                        onClick={() => updateOrderStatus(OrderStatus.NEW)}
                        style={{ height: '100%', width: '80px', margin: '0 10px', border: currentStatus === OrderStatus.NEW ? `4px solid ${MYCOLORPALETTE.FIRST}` : '' }}
                    >
                        {renderOrderStatus(undefined, OrderStatus.NEW)}
                    </Button>
                    <Button
                        disabled={![OrderStatus.NEW].includes(currentStatus!)}
                        onClick={() => updateOrderStatus(OrderStatus.CONFIRMED)}
                        style={{ height: '100%', width: '120px', margin: '0 10px', border: currentStatus === OrderStatus.CONFIRMED ? `4px solid ${MYCOLORPALETTE.FIRST}` : '' }}
                    >
                        {renderOrderStatus(undefined, OrderStatus.CONFIRMED)}
                    </Button>
                    <Button
                        disabled={![OrderStatus.NEW, OrderStatus.CONFIRMED].includes(currentStatus!)}
                        onClick={() => updateOrderStatus(OrderStatus.PREPARING)}
                        style={{ height: '100%', width: '120px', margin: '0 10px', border: currentStatus === OrderStatus.PREPARING ? `4px solid ${MYCOLORPALETTE.FIRST}` : '' }}
                    >
                        {renderOrderStatus(undefined, OrderStatus.PREPARING)}
                    </Button>
                    {currentOrderType === OrderType.PICKUP ? (
                        <Button
                            onClick={() => updateOrderStatus(OrderStatus.READYFORPICKUP)}
                            disabled={![OrderStatus.NEW, OrderStatus.CONFIRMED, OrderStatus.PREPARING].includes(currentStatus!)}
                            style={{
                                height: '100%',
                                width: '120px',
                                margin: '0 10px',
                                border: currentStatus === OrderStatus.READYFORPICKUP ? `4px solid ${MYCOLORPALETTE.FIRST}` : '',
                            }}
                        >
                            {renderOrderStatus(undefined, OrderStatus.READYFORPICKUP)}
                        </Button>
                    ) : null}
                    {currentOrderType === OrderType.DELIVERY ? (
                        <Button
                            disabled={true}
                            style={{
                                height: '100%',
                                width: '120px',
                                margin: '0 10px',
                                border: currentStatus === OrderStatus.ONDELIVERY ? `4px solid ${MYCOLORPALETTE.FIRST}` : '',
                            }}
                        >
                            {renderOrderStatus(undefined, OrderStatus.ONDELIVERY)}
                        </Button>
                    ) : null}
                    {currentOrderType === OrderType.DELIVERY ? (
                        <Button
                            disabled={true}
                            style={{ height: '100%', width: '120px', margin: '0 10px', border: currentStatus === OrderStatus.DELIVERED ? `4px solid ${MYCOLORPALETTE.FIRST}` : '' }}
                        >
                            {renderOrderStatus(undefined, OrderStatus.DELIVERED)}
                        </Button>
                    ) : null}
                </div>
                <Button
                    onClick={() => setShowDbOrderId()}
                    icon={<CloseOutlined style={{ color: MYCOLORPALETTE.SECOND }} />}
                    style={{ backgroundColor: MYCOLORPALETTE.LIGHTWHITE, borderColor: MYCOLORPALETTE.SECOND, height: '60%' }}
                >
                    <b style={{ color: MYCOLORPALETTE.SECOND }}>{t('Close')}</b>
                </Button>
            </div>
        );
    };

    return (
        <Card title={t('Orders')} style={{ margin: 20, height: '100%' }}>
            <Table
                style={{ border: `1px solid ${MYCOLORPALETTE.FIRST}` }}
                dataSource={DbOrders}
                columns={tableColums}
                rowKey="key"
                onRow={(orderItem) => ({
                    onClick: () => setShowDbOrderId(orderItem.id),
                })}
            />
            <Modal width={'70vw'} style={{ top: 50 }} open={!!ShowDbOrderId} onCancel={() => setShowDbOrderId()} footer={renderModalFooter()}>
                {ShowDbOrderId ? <OrderModal closeModal={() => setShowDbOrderId()} dbOrderId={ShowDbOrderId} /> : null}
            </Modal>
        </Card>
    );
};

export default OrdersScreen;
