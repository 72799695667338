import React from 'react';
import { Col } from 'antd';
import { OpeningHours } from '@tout-ma/general_models';
import { t } from 'i18next';

const OpeningHoursOverview = ({ hours }: { hours: OpeningHours }) => {
    const style = {
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center',
        fontWeight: '600',
    } as any;

    const closed = () => {
        return (
            <React.Fragment>
                <span style={{ color: 'red' }}>{t('Closed')}</span>
            </React.Fragment>
        );
    };

    const Shifts = (startShift1?: string, endShift1?: string, startShift2?: string, endShift2?: string) => {
        return (
            <React.Fragment>
                <span>
                    {startShift1} - {endShift1}
                </span>
                {startShift2 ? (
                    <span>
                        {startShift2} - {endShift2}
                    </span>
                ) : (
                    ''
                )}
            </React.Fragment>
        );
    };

    return (
        <React.Fragment>
            <Col style={style} span={3}>
                {hours.mondayStartShift1 ? Shifts(hours.mondayStartShift1, hours.mondayEndShift1, hours.mondayStartShift2, hours.mondayEndShift2) : closed()}
            </Col>
            <Col style={style} span={3}>
                {hours.thursdayStartShift1 ? Shifts(hours.tuesdayStartShift1, hours.tuesdayEndShift1, hours.tuesdayStartShift2, hours.tuesdayEndShift2) : closed()}
            </Col>
            <Col style={style} span={3}>
                {hours.wednesdayStartShift1 ? Shifts(hours.wednesdayStartShift1, hours.wednesdayEndShift1, hours.wednesdayStartShift2, hours.wednesdayEndShift2) : closed()}
            </Col>
            <Col style={style} span={3}>
                {hours.thursdayStartShift1 ? Shifts(hours.thursdayStartShift1, hours.thursdayEndShift1, hours.thursdayStartShift2, hours.thursdayEndShift2) : closed()}
            </Col>
            <Col style={style} span={3}>
                {hours.fridayStartShift1 ? Shifts(hours.fridayStartShift1, hours.fridayEndShift1, hours.fridayStartShift2, hours.fridayEndShift2) : closed()}
            </Col>
            <Col style={style} span={3}>
                {hours.saturdayStartShift1 ? Shifts(hours.saturdayStartShift1, hours.saturdayEndShift1, hours.saturdayStartShift2, hours.saturdayEndShift2) : closed()}
            </Col>
            <Col style={style} span={3}>
                {hours.sundayStartShift1 ? Shifts(hours.sundayStartShift1, hours.sundayEndShift1, hours.sundayStartShift2, hours.sundayEndShift2) : closed()}
            </Col>
        </React.Fragment>
    );
};

export default OpeningHoursOverview;
