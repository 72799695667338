import { Button, Card, Table, TableColumnsType, Tag, Modal } from 'antd';
import { t } from 'i18next';
import React, { useContext } from 'react';
import { MYCOLORPALETTE } from '../../constants/theme';
import { DbRestaurantDriver, RestauntContext } from '../../contexts/RestaurantContext';
import { ExclamationCircleOutlined, PlusOutlined, ReloadOutlined } from '@ant-design/icons';
import { changeAccessDbRestaurantDriverApi, getLinkDriverTokenApi } from '../../api/drivers';

const DriversScreen = () => {
    const { DbRestaurantDrivers, loadDbRestaurantDrivers } = useContext(RestauntContext);

    const changeAccesModal = (driver: DbRestaurantDriver) => {
        Modal.confirm({
            title: driver.active ? t('Deactivate driver') : t('Activate driver'),
            icon: <ExclamationCircleOutlined />,
            content: driver.active ? t('Are you sure you want to deactivate this driver?') : t('Are you sure you want to activate this driver?'),
            okText: driver.active ? t('Deactivate') : t('Activate'),
            cancelText: t('Cancel'),
            onOk: () => changeAccesForUser(driver),
        });
    };

    const getLinkDriverToken = async () => {
        try {
            let token = await getLinkDriverTokenApi();
            Modal.warning({
                title: t('Token is only valid for 2 minutes!'),
                content: (
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column',
                            width: '300px',
                            height: '100px',
                        }}
                    >
                        <b style={{ fontSize: 40, marginBottom: '10px' }}>{token}</b>
                    </div>
                ),
            });
        } catch (error: any) {
            console.log(error);
        }
    };

    const changeAccesForUser = async (driver: DbRestaurantDriver) => {
        try {
            await changeAccessDbRestaurantDriverApi(driver.driverConfigId);
            await loadDbRestaurantDrivers?.();
        } catch (error) {}
    };

    const tableColums: TableColumnsType<DbRestaurantDriver> = [
        {
            title: t('First name'),
            dataIndex: 'firstName',
            key: 'id',
            align: 'center',
        },
        {
            title: t('Last name'),
            dataIndex: 'lastName',
            align: 'center',
        },
        {
            title: t('Mobile phone'),
            dataIndex: 'mobilePhone',
            align: 'center',
        },
        {
            title: t('Active'),
            dataIndex: 'active',
            align: 'center',
            render: (item) =>
                item ? (
                    <Tag color={MYCOLORPALETTE.FOURTH} style={{ fontWeight: 'bold' }}>
                        {t('Active')}
                    </Tag>
                ) : (
                    <Tag color={MYCOLORPALETTE.SECOND} style={{ fontWeight: 'bold' }}>
                        {t('No access')}
                    </Tag>
                ),
        },
        {
            align: 'center',
            render: (item) => (
                <Button style={{ fontWeight: 'bold' }} onClick={() => changeAccesModal(item)}>
                    {item.active ? t('Disable') : t('Activate')}
                </Button>
            ),
        },
    ];
    return (
        <Card title={t('Orders')} style={{ margin: 20, height: '100%' }}>
            <Button icon={<PlusOutlined/>} style={{ marginBottom: 20 }} onClick={getLinkDriverToken}>
                {t('Add driver')}
            </Button>
            <Button icon={<ReloadOutlined/>} style={{ marginBottom: 20 , marginLeft:20}} onClick={loadDbRestaurantDrivers}>
                {t('Refresh')}
            </Button>
            <Table
                style={{ border: `1px solid ${MYCOLORPALETTE.FIRST}` }}
                dataSource={DbRestaurantDrivers}
                columns={tableColums}
                rowKey="id"
                onRow={(orderItem) => ({
                    onClick: () => console.log(orderItem),
                })}
            />
            {/* <Modal width={'70vw'} style={{ top: 50 }} open={!!ShowDbOrderId} onCancel={() => setShowDbOrderId()} footer={renderModalFooter()}>
                {ShowDbOrderId ? <OrderModal closeModal={() => setShowDbOrderId()} dbOrderId={ShowDbOrderId} /> : null}
            </Modal> */}
        </Card>
    );
};

export default DriversScreen;
