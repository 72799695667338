import React, { useEffect, useState } from 'react';
import { Row, Col, Button } from 'antd';
import { OpeningHours } from '@tout-ma/general_models';
import { t } from 'i18next';
import { format, isBefore, parse } from 'date-fns';
import MyTimePicker from '../antdPickers/TimePicker';

const OpeningHoursOneDay = ({ dayOfWeek, openingHours, setopeninghours }: { dayOfWeek: number; openingHours: OpeningHours; setopeninghours: (prev: OpeningHours) => void }) => {
    const [closed, setClosed] = useState<boolean>();
    const [twoshifts, settwoshifts] = useState<boolean>();
    const [error, seterror] = useState<boolean>();
    const dayOfWeekString = [t('Mon'), t('Tue'), t('Wed'), t('Thu'), t('Fri'), t('Sat'), t('Sun')];

    const rowstyle = {
        alignItems: 'center',
        textAlign: 'center',
        marginTop: 10,
    } as any;
    const formatDay = 'HH:mm';

    const changeValue = (newValue: Date | undefined, shiftNr: number) => {
        let currHours = openingHours;
        switch (dayOfWeek) {
            case 0:
                switch (shiftNr) {
                    case 0:
                        currHours.mondayStartShift1 = newValue ? format(newValue, formatDay) : undefined;
                        break;
                    case 1:
                        currHours.mondayEndShift1 = newValue ? format(newValue, formatDay) : undefined;
                        break;
                    case 2:
                        currHours.mondayStartShift2 = newValue ? format(newValue, formatDay) : undefined;
                        break;
                    case 3:
                        currHours.mondayEndShift2 = newValue ? format(newValue, formatDay) : undefined;
                        break;
                    default:
                        break;
                }
                break;
            case 1:
                switch (shiftNr) {
                    case 0:
                        currHours.tuesdayStartShift1 = newValue ? format(newValue, formatDay) : undefined;
                        break;
                    case 1:
                        currHours.tuesdayEndShift1 = newValue ? format(newValue, formatDay) : undefined;
                        break;
                    case 2:
                        currHours.tuesdayStartShift2 = newValue ? format(newValue, formatDay) : undefined;
                        break;
                    case 3:
                        currHours.tuesdayEndShift2 = newValue ? format(newValue, formatDay) : undefined;
                        break;
                    default:
                        break;
                }
                break;
            case 2:
                switch (shiftNr) {
                    case 0:
                        currHours.wednesdayStartShift1 = newValue ? format(newValue, formatDay) : undefined;
                        break;
                    case 1:
                        currHours.wednesdayEndShift1 = newValue ? format(newValue, formatDay) : undefined;
                        break;
                    case 2:
                        currHours.wednesdayStartShift2 = newValue ? format(newValue, formatDay) : undefined;
                        break;
                    case 3:
                        currHours.wednesdayEndShift2 = newValue ? format(newValue, formatDay) : undefined;
                        break;
                    default:
                        break;
                }
                break;
            case 3:
                switch (shiftNr) {
                    case 0:
                        currHours.thursdayStartShift1 = newValue ? format(newValue, formatDay) : undefined;
                        break;
                    case 1:
                        currHours.thursdayEndShift1 = newValue ? format(newValue, formatDay) : undefined;
                        break;
                    case 2:
                        currHours.thursdayStartShift2 = newValue ? format(newValue, formatDay) : undefined;
                        break;
                    case 3:
                        currHours.thursdayEndShift2 = newValue ? format(newValue, formatDay) : undefined;
                        break;
                    default:
                        break;
                }
                break;
            case 4:
                switch (shiftNr) {
                    case 0:
                        currHours.fridayStartShift1 = newValue ? format(newValue, formatDay) : undefined;
                        break;
                    case 1:
                        currHours.fridayEndShift1 = newValue ? format(newValue, formatDay) : undefined;
                        break;
                    case 2:
                        currHours.fridayStartShift2 = newValue ? format(newValue, formatDay) : undefined;
                        break;
                    case 3:
                        currHours.fridayEndShift2 = newValue ? format(newValue, formatDay) : undefined;
                        break;
                    default:
                        break;
                }
                break;
            case 5:
                switch (shiftNr) {
                    case 0:
                        currHours.saturdayStartShift1 = newValue ? format(newValue, formatDay) : undefined;
                        break;
                    case 1:
                        currHours.saturdayEndShift1 = newValue ? format(newValue, formatDay) : undefined;
                        break;
                    case 2:
                        currHours.saturdayStartShift2 = newValue ? format(newValue, formatDay) : undefined;
                        break;
                    case 3:
                        currHours.saturdayEndShift2 = newValue ? format(newValue, formatDay) : undefined;
                        break;
                    default:
                        return '00:00';
                }
                break;
            case 6:
                switch (shiftNr) {
                    case 0:
                        currHours.sundayStartShift1 = newValue ? format(newValue, formatDay) : undefined;
                        break;
                    case 1:
                        currHours.sundayEndShift1 = newValue ? format(newValue, formatDay) : undefined;
                        break;
                    case 2:
                        currHours.sundayStartShift2 = newValue ? format(newValue, formatDay) : undefined;
                        break;
                    case 3:
                        currHours.sundayEndShift2 = newValue ? format(newValue, formatDay) : undefined;
                        break;
                    default:
                        break;
                }
                break;
            default:
                break;
        }
        setopeninghours(currHours);
    };
    
    const onClosedOrOpenClicked = () => {
        if (closed) {
            setClosed((prev) => !prev);
            changeValue(parse('11:00', formatDay, new Date()), 0);
            changeValue(parse('14:00', formatDay, new Date()), 1);
            changeValue(undefined, 2);
            changeValue(undefined, 3);
        } else {
            changeValue(undefined, 0);
            changeValue(undefined, 1);
            changeValue(undefined, 2);
            changeValue(undefined, 3);
            setClosed(true);
        }
    };

    const onTwoShiftsClicked = () => {
        if (getTimeByDayAndShiftNr(2) === '00:00') {
            settwoshifts((prev) => !prev);
            changeValue(parse('17:00', formatDay, new Date()), 2);
            changeValue(parse('23:00', formatDay, new Date()), 3);
        } else {
            settwoshifts((prev) => !prev);
            changeValue(undefined, 2);
            changeValue(undefined, 3);
        }
    };

    useEffect(() => {
        seterror(false);
        setClosed(getTimeByDayAndShiftNr(0) === '00:00' ? true : false);
        settwoshifts(getTimeByDayAndShiftNr(2) !== '00:00' ? true : false);
        if (getTimeByDayAndShiftNr(2) !== '00:00' && isBefore(getTimeByDayAndShiftNr(2), getTimeByDayAndShiftNr(1))) {
            seterror(true);
        }
        return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [openingHours]);

    const getTimeByDayAndShiftNr = (shiftNr: number) => {
        switch (dayOfWeek) {
            case 0:
                switch (shiftNr) {
                    case 0:
                        return openingHours.mondayStartShift1 || '00:00';
                    case 1:
                        return openingHours.mondayEndShift1 || '00:00';
                    case 2:
                        return openingHours.mondayStartShift2 || '00:00';
                    case 3:
                        return openingHours.mondayEndShift2 || '00:00';
                    default:
                        return '00:00';
                }
            case 1:
                switch (shiftNr) {
                    case 0:
                        return openingHours.tuesdayStartShift1 || '00:00';
                    case 1:
                        return openingHours.tuesdayEndShift1 || '00:00';
                    case 2:
                        return openingHours.tuesdayStartShift2 || '00:00';
                    case 3:
                        return openingHours.tuesdayEndShift2 || '00:00';
                    default:
                        return '00:00';
                }
            case 2:
                switch (shiftNr) {
                    case 0:
                        return openingHours.wednesdayStartShift1 || '00:00';
                    case 1:
                        return openingHours.wednesdayEndShift1 || '00:00';
                    case 2:
                        return openingHours.wednesdayStartShift2 || '00:00';
                    case 3:
                        return openingHours.wednesdayEndShift2 || '00:00';
                    default:
                        return '00:00';
                }
            case 3:
                switch (shiftNr) {
                    case 0:
                        return openingHours.thursdayStartShift1 || '00:00';
                    case 1:
                        return openingHours.thursdayEndShift1 || '00:00';
                    case 2:
                        return openingHours.thursdayStartShift2 || '00:00';
                    case 3:
                        return openingHours.thursdayEndShift2 || '00:00';
                    default:
                        return '00:00';
                }
            case 4:
                switch (shiftNr) {
                    case 0:
                        return openingHours.fridayStartShift1 || '00:00';
                    case 1:
                        return openingHours.fridayEndShift1 || '00:00';
                    case 2:
                        return openingHours.fridayStartShift2 || '00:00';
                    case 3:
                        return openingHours.fridayEndShift2 || '00:00';
                    default:
                        return '00:00';
                }
            case 5:
                switch (shiftNr) {
                    case 0:
                        return openingHours.saturdayStartShift1 || '00:00';
                    case 1:
                        return openingHours.saturdayEndShift1 || '00:00';
                    case 2:
                        return openingHours.saturdayStartShift2 || '00:00';
                    case 3:
                        return openingHours.saturdayEndShift2 || '00:00';
                    default:
                        return '00:00';
                }
            case 6:
                switch (shiftNr) {
                    case 0:
                        return openingHours.sundayStartShift1 || '00:00';
                    case 1:
                        return openingHours.sundayEndShift1 || '00:00';
                    case 2:
                        return openingHours.sundayStartShift2 || '00:00';
                    case 3:
                        return openingHours.sundayEndShift1 || '00:00';
                    default:
                        return '00:00';
                }
            default:
                return '00:00';
        }
    };

    return (
        <Row style={rowstyle}>
            <Col span={4}>{dayOfWeekString[dayOfWeek]}</Col>
            <Col span={4}>
                <Button  danger={!closed} onClick={onClosedOrOpenClicked}>
                    {' '}
                    {closed ? t('Open?') : t('Closed?')}
                </Button>
            </Col>
            {!closed && (
                <React.Fragment>
                    <Col span={3}>
                        <MyTimePicker
                            defaultValue ={parse(getTimeByDayAndShiftNr(0), formatDay, new Date())}
                            onChange={(newMoment) => changeValue(newMoment, 0)}
                            format={formatDay}
                            allowClear={false}
                            status={error ? 'error' : ''}
                        />
                    </Col>
                    <Col span={3}>
                        <MyTimePicker
                            defaultValue={parse(getTimeByDayAndShiftNr(1), formatDay, new Date())}
                            onChange={(newMoment) => changeValue(newMoment, 1)}
                            format={formatDay}
                            allowClear={false}
                            status={error ? 'error' : ''}
                        />
                    </Col>

                    <Col span={4}>
                        <Button danger={twoshifts} onClick={onTwoShiftsClicked}>
                            {twoshifts ? 'Remove Shift?' : 'Add Shift?'}
                        </Button>
                    </Col>
                    {twoshifts && (
                        <React.Fragment>
                            <Col span={3}>
                                <MyTimePicker
                                    defaultValue={parse(getTimeByDayAndShiftNr(2), formatDay, new Date())}
                                    onChange={(newMoment) => changeValue(newMoment, 2)}
                                    format={formatDay}
                                    allowClear={false}
                                    status={error ? 'error' : ''}
                                />
                            </Col>
                            <Col span={3}>
                                <MyTimePicker
                                    defaultValue={parse(getTimeByDayAndShiftNr(3), formatDay, new Date())}
                                    onChange={(newMoment) => changeValue(newMoment, 3)}
                                    format={formatDay}
                                    allowClear={false} 
                                    status={error ? 'error' : ''}
                                />
                            </Col>
                        </React.Fragment>
                    )}
                </React.Fragment>
            )}
        </Row>
    );
};

export default OpeningHoursOneDay;
