import { Col, Row } from 'antd'
import { t } from 'i18next'
import React from 'react'

const OpeningHoursHeader = () => {
    const style = {
        display: 'flex',
        flexDirection: 'column',
        textAlign:"center" ,
        fontWeight:'600'
    } as any
    return (
        <Row style={{ alignItems: 'center' }}>
            <Col span={3}  style={style} > </Col>
            <Col span={3}  style={style} >{t('Mon')}</Col>
            <Col span={3}  style={style} >{t('Tue')}</Col>
            <Col span={3}  style={style} >{t('Wed')}</Col>
            <Col span={3}  style={style} >{t('Thu')}</Col>
            <Col span={3}  style={style} >{t('Fri')}</Col>
            <Col span={3}  style={style} >{t('Sat')}</Col>
            <Col span={3}  style={style} >{t('Sun')}</Col>
        </Row>)
}

export default OpeningHoursHeader