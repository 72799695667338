import axios from 'axios';

export interface PaginatedResult<T> {
    limit: number;
    offset: number;
    total: number;
    list: T[];
}

const Api = axios.create({
    timeout: 20000,
    baseURL: '', // localhost
});



export default Api;
