import { Menu, Modal } from 'antd';

import React, { useContext } from 'react';
import { RestauntContext } from '../contexts/RestaurantContext';
import { useNavigate } from 'react-router-dom';
import { MenuItemType } from 'antd/es/menu/hooks/useItems';
import { AuthContext } from '../contexts/AuthContext';
import { t } from 'i18next';
import { ExclamationCircleFilled } from '@ant-design/icons';

const { confirm } = Modal;

const SideMenuLinks = () => {
    const navigate = useNavigate();
    const { Restaurant } = useContext(RestauntContext);
    const { logout } = useContext(AuthContext);

    const onMenuClick = async (menuItem: MenuItemType) => {
        if (menuItem.key !== 'signOut') {
            navigate(menuItem.key as string);
        } else {
            confirm({
                title: t('Are you sure u want to log out?'),
                icon: <ExclamationCircleFilled />,
                content: t('U need the email and the password from the owner to log back in!'),
                okText: t('Yes, log out'),
                okType: 'danger',
                cancelText: t('No'),
                onOk() {
                    logout?.();
                },
            });
        }
    };

    const menuItems = [
        {
            key: 'name',
            label: Restaurant?.name || 'Loading...',
            disabled: true,
        },
        {
            key: '/',
            label: t('Orders'),
        },
        {
            key: 'map',
            label: t('Map'),
        },
        {
            key: 'order-history',
            label: t('Order History'),
        },
        {
            key: 'menu',
            label: t('Menu'),
        },
        {
            key: 'drivers',
            label: t('Drivers'),
        },
        {
            key: 'settings',
            label: t('Settings'),
        },
        {
            key: 'signOut',
            label: t('Sign Out'),
            danger: true,
        },
    ];

    return (
        <React.Fragment>
            <Menu items={menuItems} onClick={onMenuClick} />
        </React.Fragment>
    );
};

export default SideMenuLinks;
