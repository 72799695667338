import { useContext, useEffect, useState } from 'react';
import { Map, Marker, useMap, useMapsLibrary } from '@vis.gl/react-google-maps';
import { RestauntContext } from '../../contexts/RestaurantContext';

const NewOrderMap = ({ order_Lat, order_Lng, setRoute }: { order_Lat?: number; order_Lng?: number; setRoute: any}) => {
    const { Restaurant } = useContext(RestauntContext);

    const map = useMap('orderRoute');
    const routesLibrary = useMapsLibrary('routes');
    const [directionsService, setDirectionsService] = useState<google.maps.DirectionsService>();
    const [directionsRenderer, setDirectionsRenderer] = useState<google.maps.DirectionsRenderer>();
    const [routes, setRoutes] = useState<google.maps.DirectionsRoute[]>([]);
    const selected = routes[0];
    setRoute((e:any) => selected?.legs[0]);

    // Initialize directions service and renderer
    useEffect(() => {
        if (!routesLibrary || !map) return;
        setDirectionsService(new routesLibrary.DirectionsService());
        setDirectionsRenderer(new routesLibrary.DirectionsRenderer({ map }));
    }, [routesLibrary, map]);

    useEffect(() => {
        if (!Restaurant) return;
        if (!directionsService || !directionsRenderer) return;
        console.log({order_Lat, order_Lng})
        if (!order_Lat || !order_Lng) return;

        directionsService
            .route({
                origin: { lat: Restaurant?.lat ?? 0, lng: Restaurant?.lng ?? 0 },
                destination: { lat: order_Lat ?? 0, lng: order_Lng ?? 0 },
                travelMode: google.maps.TravelMode.DRIVING,
                provideRouteAlternatives: false,
            })
            .then((response) => {
                directionsRenderer.setDirections(response);
                setRoutes(response.routes);
            });

        return () => {
            directionsRenderer.setMap(null);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [directionsService, directionsRenderer, Restaurant, order_Lat, order_Lng]);

    // Update direction route
    useEffect(() => {
        if (!directionsRenderer) return;
        directionsRenderer.setRouteIndex(0);
    }, [directionsRenderer]);

    return (
        <Map
            defaultCenter={{ lat: Restaurant?.lat ?? 0, lng: Restaurant?.lng ?? 0 }}
            defaultZoom={12}
            gestureHandling={'greedy'}
            disableDefaultUI={true}
            id="orderRoute"
            mapId="orderRoute"
        >
            <Marker position={{ lat: Restaurant?.lat ?? 0, lng: Restaurant?.lng ?? 0 }} />
            <Marker position={{ lat: order_Lat ?? 0, lng: order_Lng ?? 0 }} />
        </Map>
    );
};

export default NewOrderMap;
