import React, { useContext, useEffect } from 'react';
import { RestauntContext } from '../../contexts/RestaurantContext';
import { t } from 'i18next';
import { OpeningHours } from '@tout-ma/general_models';
import { useNavigate } from 'react-router-dom';
import { Card, Row, Col, Button, Divider } from 'antd';
import OpeningHoursHeader from '../../components/openinghours/OpeningHoursHeader';
import OpeningHoursOverview from '../../components/openinghours/OpeningHoursOverview';
import OpeningHoursOneDay from '../../components/openinghours/OpeningHoursOneDay';
import { addOpeningHours, updateOpeningHours } from '../../api/openinghours';
import { ArrowLeftOutlined, PlusSquareOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { NotificationsContext } from '../../contexts/NotificationsContext';

const WorkingHoursScreen = ({ delivery }: { delivery?: boolean }) => {
    const { Restaurant, reloadRestaurant, updateRestaurant } = useContext(RestauntContext);
    const title = delivery ? t('Modify Delivery Hours') : t('Modify Pick-Up Hours');
    const format = 'HH:mm';
    const navigate = useNavigate();
    const { showNotification } = useContext(NotificationsContext);
    const [workingHours, setworkingHours] = React.useState<OpeningHours>();
    useEffect(() => {
        console.log(workingHours);
    }, [workingHours]);

    const rowstyle = {
        alignItems: 'center',
        textAlign: 'center',
        marginTop: 10,
    } as any;

    const saveHours = async () => {
        if (!workingHours || !Restaurant) return;
        try {
            if ((delivery && !Restaurant.deliveryHours) || (!delivery && !Restaurant.openingHours)) {
                await addOpeningHours?.(delivery ? 'delivery' : 'pickup', workingHours);
            } else {
                await updateOpeningHours(delivery ? 'delivery' : 'pickup', workingHours);
            }
            showNotification?.("success", 'Hours saved');
        } catch (error) {
            showNotification?.("error", 'Error occurd');

        }
        reloadRestaurant?.();
        navigate(-1);
    };

    React.useEffect(() => {
        if (Restaurant && !workingHours) {
            if (delivery) {
                setworkingHours(Restaurant.deliveryHours ?? new OpeningHours(Restaurant.id));
            } else {
                setworkingHours(Restaurant.openingHours ?? new OpeningHours(Restaurant.id));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [Restaurant]);

    return (
        <Card
            title={
                <span>
                    <Button icon={<ArrowLeftOutlined />} style={{ marginRight: 10 }} title={title} onClick={() => navigate('/settings')} /> {t('Delivery Areas')}
                </span>
            }
            style={{ margin: 20 }}
        >
            <OpeningHoursHeader />
            <Divider />
            <Row style={rowstyle}>
                <Col span={3}>{delivery ? 'Delivery' : 'Current Pick-Up Hours'}</Col>
                {workingHours ? (
                    <OpeningHoursOverview hours={workingHours} />
                ) : (
                    <Col span={21} style={{ display: 'flex', justifyContent: 'center', color: 'red' }}>
                        Not Configured yet!
                    </Col>
                )}
            </Row>
            <Divider />

            <Row style={rowstyle}>
                <Col span={8}></Col>

                <Col span={6} style={rowstyle}>
                    SHIFT 1
                </Col>

                <Col span={4}></Col>

                <Col span={6} style={rowstyle}>
                    SHIFT 2
                </Col>
            </Row>

            {workingHours && (
                <React.Fragment>
                    <OpeningHoursOneDay dayOfWeek={0} openingHours={workingHours} setopeninghours={setworkingHours} />
                    <OpeningHoursOneDay dayOfWeek={1} openingHours={workingHours} setopeninghours={setworkingHours} />
                    <OpeningHoursOneDay dayOfWeek={2} openingHours={workingHours} setopeninghours={setworkingHours} />
                    <OpeningHoursOneDay dayOfWeek={3} openingHours={workingHours} setopeninghours={setworkingHours} />
                    <OpeningHoursOneDay dayOfWeek={4} openingHours={workingHours} setopeninghours={setworkingHours} />
                    <OpeningHoursOneDay dayOfWeek={5} openingHours={workingHours} setopeninghours={setworkingHours} />
                    <OpeningHoursOneDay dayOfWeek={6} openingHours={workingHours} setopeninghours={setworkingHours} />
                </React.Fragment>
            )}

            <Row style={{ marginTop: 25 }}>
                <Col span={4}></Col>
                <Col span={16}>
                    <Button block type="primary" onClick={saveHours}>
                        Save
                    </Button>
                </Col>
            </Row>
        </Card>
    );
};

export default WorkingHoursScreen;
