import React, { useContext, useState } from 'react';
import { RestauntContext } from '../../contexts/RestaurantContext';
import { useNavigate } from 'react-router-dom';
import { t } from 'i18next';
import { Button, Form, Input, Select, Space, Card, Divider, Row, Col } from 'antd';
import { ArrowLeftOutlined, PlusSquareOutlined, GoogleOutlined, DeleteOutlined, RedoOutlined, EditOutlined, CloseOutlined, SaveOutlined } from '@ant-design/icons';
import { DefaultLocales, Restaurant } from '@tout-ma/general_models';
import DragSorting from '../../components/dragSorting/DragSorting';
import { translateText } from '../../api/translate';
import { addMenuCategory, deleteMenuCategory, updateMenuCategory } from '../../api/menuCategories';
import RestaurantMenuCategory from '@tout-ma/general_models/dist/global/RestaurantMenuCategory';
import { ColumnsType } from 'antd/es/table';

const { Option } = Select;
type formType = {
    id?: number;
    originalLang: DefaultLocales;
    name: {
        name: string;
        nl: string;
        fr: string;
        es: string;
        ar: string;
        en: string;
    };
    quickInfo: {
        quickInfo: string;
        nl: string;
        fr: string;
        es: string;
        ar: string;
        en: string;
    };
};

const MenuCategoriesScreen = () => {
    const { Restaurant, reloadRestaurant } = useContext(RestauntContext);
    const navigate = useNavigate();
    const [form] = Form.useForm<formType>();
    const languages: DefaultLocales[] = [DefaultLocales.EN, DefaultLocales.FR, DefaultLocales.ES, DefaultLocales.AR, DefaultLocales.NL];

    const [selectedLang, setselectedLang] = useState<DefaultLocales>();
    const [showAddingNewCategorie, setshowAddingNewCategorie] = useState<boolean>(false);

    const [currMenuCategories, setcurrMenuCategories] = useState<RestaurantMenuCategory[]>([]);

    const [editingCategoryId, seteditingCategoryId] = useState<number>();

    const [orderChanged, setorderChanged] = useState<boolean>(false);

    React.useEffect(() => {
        if (Restaurant && Restaurant.menuCategories && (!currMenuCategories.length || currMenuCategories.length !== Restaurant.menuCategories.length)) {
            setcurrMenuCategories(Restaurant.menuCategories);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [Restaurant]);

    React.useEffect(() => {
        if (currMenuCategories.length && !orderChanged) {
            let currOrder = 0;
            let orderChangedlocal = false;
            currMenuCategories.forEach((x) => {
                console.log(`curr step is order : ${x.orderingNumber} and currOrder is ${currOrder}`);
                if (x.orderingNumber >= currOrder) {
                    currOrder = x.orderingNumber;
                } else {
                    orderChangedlocal = true;
                }
            });
            console.log(orderChangedlocal);
            if (orderChangedlocal) {
                setorderChanged(true);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currMenuCategories]);

    const onFinish = async (values: formType) => {
        
        console.log(values);
        (values as any).name[`${selectedLang}`] = values.name.name;
        (values as any).quickInfo[`${selectedLang}`] = values.quickInfo.quickInfo;
        
        (values as any).name.originalLang = selectedLang;
        (values as any).quickInfo.originalLang = selectedLang;
        
        delete (values as any).name.name;
        delete (values as any).quickInfo.quickInfo;
        
        if(!values.id && !editingCategoryId ){
            await addMenuCategory(values);
        }else{
            await updateMenuCategory({...values, id: editingCategoryId || values.id})
        }
        form.resetFields();
        setshowAddingNewCategorie(false);
        reloadRestaurant?.();
    };

    const onTranslate = async () => {
        let currForm = form.getFieldsValue();
        if (!selectedLang) return;

        let languagesToTranslate = languages.filter((x) => x !== selectedLang);

        for (let index = 0; index < languagesToTranslate.length; index++) {
            const currLang = languagesToTranslate[index];
            const name = currForm.name.name;
            const quickInfo = currForm.quickInfo?.quickInfo;

            if (name) {
                let translatedName = await translateText(name, selectedLang, currLang);
                form.setFieldsValue({ name: { [currLang]: translatedName } });
            }
            if (quickInfo) {
                let translatedQuickInfo = await translateText(quickInfo, selectedLang, currLang);
                form.setFieldsValue({ quickInfo: { [currLang]: translatedQuickInfo } });
            }
        }
    };
    const onEditMenuCategory = (menuCategory: any) => {
        console.log(menuCategory);
        form.setFieldsValue({
            originalLang: menuCategory.name.originalLang,
            name: menuCategory.name,
            quickInfo: menuCategory.quickInfo,
            id: menuCategory.id
        });
        seteditingCategoryId(menuCategory.id);
        setshowAddingNewCategorie(true);
    };
    const onMenuCategoryDelete = async () => {
        if (editingCategoryId) {
            await deleteMenuCategory(editingCategoryId);
            seteditingCategoryId(undefined);
            setshowAddingNewCategorie(false);
            reloadRestaurant?.();
        }
    };

    const saveNewOrder = async () => {
        for (let index = 0; index < currMenuCategories.length; index++) {
            const element = currMenuCategories[index];
            await updateMenuCategory({ ...element, orderingNumber: index });
        }

        setorderChanged(false);
        reloadRestaurant?.();
    };

    const collumns: ColumnsType<RestaurantMenuCategory> = [
        {
            key: 'sort',
            title: 'Sort',
            width: 50,
        },
        {
            title: 'Name',
            key: 'name',
            render: (rmc: RestaurantMenuCategory) => <span>{rmc.name[rmc.name.originalLang as DefaultLocales]}</span>,
        },
        {
            title: 'Extra Info',
            key: 'quickInfo',
            render: (rmc: RestaurantMenuCategory) => <span>{rmc.quickInfo[rmc.quickInfo.originalLang as DefaultLocales]}</span>,
        },
        {
            title: 'Items',
            dataIndex: '',
            key: 'y',
            width: 50,
            render: (row: RestaurantMenuCategory) => (
                <p style={{ textAlign: 'center', margin: 0, padding: 0, fontWeight: 'bold' }}>{Restaurant?.dishes?.filter((x) => x.menuCategoryId === row.id)?.length}</p>
            ),
        },
        {
            title: 'Edit',
            dataIndex: '',
            key: 'x',
            align: 'center',
            width: 50,
            render: (row: RestaurantMenuCategory) => <Button icon={<EditOutlined />} title={t('Edit')} onClick={() => onEditMenuCategory(row)} />,
        },
    ];

    return (
        <Card
            title={
                <span>
                    <Button icon={<ArrowLeftOutlined />} style={{ marginRight: 10 }} title={t('Go Back')} onClick={() => navigate(-1)} /> {t('Menu Categories')}
                </span>
            }
            style={{ margin: 20 }}
        >
            <Card>
                {showAddingNewCategorie ? (
                    <Form layout="vertical" form={form} name="newCategorie" onFinish={onFinish}>
                        <Row style={{ alignItems: 'center' }}>
                            <Col span={6} offset={1}>
                                <Form.Item name="originalLang" labelAlign="left" label="Input language" rules={[{ required: true }]}>
                                    <Select onChange={setselectedLang}>
                                        <Select.Option value="en">{t('English')}</Select.Option>
                                        <Select.Option value="fr">{t('French')}</Select.Option>
                                        <Select.Option value="es">{t('Spanish')}</Select.Option>
                                        <Select.Option value="ar">{t('Arabic')}</Select.Option>
                                        <Select.Option value="nl">{t('Dutch')}</Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row style={{ alignItems: 'center' }}>
                            <Col span={10} offset={1}>
                                <Form.Item name={['name', 'name']} label="name" rules={[{ required: true }]}>
                                    <Input.TextArea />
                                </Form.Item>
                            </Col>
                            <Col span={10} offset={1}>
                                <Form.Item name={['quickInfo', 'quickInfo']} label="extra info" rules={[{ required: false }]}>
                                    <Input.TextArea />
                                </Form.Item>
                            </Col>
                        </Row>
                        {languages
                            .filter((x) => x !== selectedLang)
                            .map((lang) => (
                                <React.Fragment key={lang}>
                                    <Row>
                                        <Col span={10} offset={1}>
                                            <Form.Item name={['name', lang]} label={`Name in '${lang}'`} rules={[{ required: true }]}>
                                                <Input.TextArea />
                                            </Form.Item>
                                        </Col>
                                        <Col span={10} offset={1}>
                                            <Form.Item name={['quickInfo', lang]} label={`Extra Info in '${lang}'`} rules={[{ required: false }]}>
                                                <Input.TextArea />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </React.Fragment>
                            ))}

                        <Row>
                            <Col span={5} offset={1}>
                                <Button type="primary" disabled={!selectedLang} block htmlType="button" icon={<GoogleOutlined />} onClick={onTranslate}>
                                    Translate text
                                </Button>
                            </Col>
                            <Col span={5} offset={1}>
                                <Button type='default'  style={{border: '2px solid green', color: 'green'}}  block icon={<PlusSquareOutlined />} htmlType="submit">
                                  { editingCategoryId ? 'Update category': 'Create category'}
                                </Button>
                            </Col>
                            <Col span={5} offset={1}>
                                <Button htmlType="button" block icon={<RedoOutlined />} onClick={() => form.resetFields()}>
                                    Reset form
                                </Button>
                            </Col>
                            <Col span={5} offset={1}>
                                <Button
                                    htmlType="button"
                                    block
                                    icon={editingCategoryId ? <DeleteOutlined /> : <CloseOutlined />}
                                    danger
                                    onClick={() => {
                                        if (editingCategoryId) {
                                            onMenuCategoryDelete();
                                        } else {
                                            form.resetFields();
                                            setshowAddingNewCategorie(false);
                                        }
                                    }}
                                >
                                    {editingCategoryId ? 'Delete' : 'Cancel'}
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                ) : (
                    <Button block icon={<PlusSquareOutlined />} onClick={() => setshowAddingNewCategorie(true)}>
                        Add new category
                    </Button>
                )}
            </Card>
            <Divider />
            <Card>
                {Restaurant && (
                    <React.Fragment>
                        <DragSorting dataSource={currMenuCategories} setDataSource={setcurrMenuCategories} columns={collumns} />
                        {orderChanged && (
                            <Space style={{ position: 'absolute', left: 10, bottom: 25 }}>
                                <Button
                                    htmlType="button"
                                    block
                                    icon={<RedoOutlined />}
                                    onClick={() => {
                                        setcurrMenuCategories(Restaurant.menuCategories);
                                        setorderChanged(false);
                                    }}
                                >
                                    Reset
                                </Button>
                                <Button htmlType="button" block icon={<SaveOutlined />} onClick={saveNewOrder}>
                                    {t('Save new order')}
                                </Button>
                            </Space>
                        )}
                    </React.Fragment>
                )}
            </Card>
        </Card>
    );
};

export default MenuCategoriesScreen;
