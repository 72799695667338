import { User } from "@tout-ma/general_models";
import Axios from ".";
import env from "../constants/env";
export function getDbUserWithJwtToken(
    bearerToken: string
): Promise<User> {
    return new Promise(async (resolve, reject) => {
        try {
            const { data } = await Axios({
                method: 'POST',
                url: `${env.api_base_url}${env.services.users}/v1/auth/jwt`,
                data: {
                    bearerToken
                },
            });

            resolve(data);
            return;
        } catch (error: any) {
            console.error('getDbUserWithJwtToken', error);
            reject(error);
        }
    });
}

export function getTokenData(
    token: string
): Promise<string> {
    return new Promise(async (resolve, reject) => {
        try {
            const { data } = await Axios({
                method: 'GET',
                url: `${env.api_base_url}${env.services.users}/v1/token/adminlogintoken/${token}`,
            });
            console.log(data)
            resolve(data);
            return;
        } catch (error: any) {
            console.error('getDbUserWithJwtToken', error);
            reject(error);
        }
    });
}
