import { createContext } from 'react';
import { notification } from 'antd';

type NotificationType = 'success' | 'info' | 'warning' | 'error';
type NotificationsContextType = {showNotification?: (type: NotificationType, title?: string, description?: string) => void};

export const NotificationsContext = createContext<NotificationsContextType>({});

export const NotificationsContextProvider = ({ children }: { children: React.ReactNode | React.ReactNode[] }) => {
    const [api, contextHolder] = notification.useNotification();

    const showNotification = (type: NotificationType, title?: string, description?: string) => {
        api[type]({
            message: title,
            description,
            duration:10
        });
    };

    return (
        <NotificationsContext.Provider value={{showNotification}}>
            {contextHolder}
            {children}
        </NotificationsContext.Provider>
    );
};
