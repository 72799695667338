import * as React from 'react';

import MyDatePicker from './DatePickers';
import { PickerTimeProps } from 'antd/es/time-picker';

export interface TimePickerProps extends Omit<PickerTimeProps<Date>, 'picker'> {}

const MyTimePicker = React.forwardRef<any, TimePickerProps>((props, ref) => (
  <MyDatePicker {...props} picker="time" mode={undefined} ref={ref} />
));

MyTimePicker.displayName = 'TimePicker';

export default MyTimePicker;
